import {
  ENABLE_DASHBOARD_LINKING,
  DISABLE_DASHBOARD_LINKING,
  RESET_DASHBOARD_LINKING_POPUP,
} from './types';

export const enableDashboardLinking = (value) => ({
  type: ENABLE_DASHBOARD_LINKING,
  payload: value,
});

export const disableDashboardLinking = () => ({
  type: DISABLE_DASHBOARD_LINKING,
});

export const resetDashboardLinkingPopup = () => ({
  type: RESET_DASHBOARD_LINKING_POPUP,
});
