import { countries } from '../../../helpers/country';

export const regexReplace = /_/gi;
export const regexReplaceCommas = new RegExp(',', 'g');
export const zoomTres = 11.6;
export const ZOOM_CONFIG = { low: 12, mid: 14, high: 16 };
export const POINT_OF_INTEREST_IN_ZOOM = 10;

export const indicators = {
  Unemployment: 'Unemployment',
  'Population by age and gender': 'Population by age and gender',
  'Population by income': 'Population by income',
  'Population by education': 'Population by education',
  'Services mix': 'Services mix',
  'Living convenience': 'Living convenience',
  'Area development': 'Area development',
  Footfall: 'Footfall',
  'Population structure': 'Population structure',
  'Advanced household types': 'Advanced household types',
  'Residential demand-supply': 'Residential demand-supply',
  'Property market': 'Property market',
  'Office market': 'Office market',
};

export const demographicIndicators = [
  indicators.Unemployment,
  indicators['Population by age and gender'],
  indicators['Population by income'],
  indicators['Population by education'],
];

const mapOptionsFi = {
  'Population by income': [{ name: 'Average income', unit: '€' }],
  Unemployment: [{ name: 'Unemployment rate', unit: '%' }],
};

const mapOptionsDk = {
  'Population by income': [{ name: 'Average income', unit: ' DKK' }],
  Unemployment: [{ name: 'Unemployment rate', unit: '%' }],
};

export const getMapOptions = (country) => {
  if (country === countries.FINLAND) {
    return mapOptionsFi;
  }
  if (country === countries.DENMARK) {
    return mapOptionsDk;
  }
  return {};
};
