import { indicators } from '../pages/forecast/maps/constants';
import { updateFootfallDashboard } from './footfall';

export function updateIndicatorData(indicatorParams) {
  switch (indicatorParams.indicatorName) {
    case indicators.Footfall:
      return updateFootfallDashboard(indicatorParams);
    default:
      return Promise.resolve();
  }
}
