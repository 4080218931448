import {
  ENABLE_DASHBOARD_LINKING,
  DISABLE_DASHBOARD_LINKING,
  RESET_DASHBOARD_LINKING_POPUP,
} from '../actions/types';

export default function (
  state = { from: '', isActive: false, popup: null, mapBound: null },
  action,
) {
  switch (action.type) {
    case ENABLE_DASHBOARD_LINKING:
      return { ...state, isActive: true, ...action.payload };

    case DISABLE_DASHBOARD_LINKING:
      return { ...state, isActive: false, from: '', to: '' };

    case RESET_DASHBOARD_LINKING_POPUP:
      return { ...state, popup: null };

    default:
      return state;
  }
}
