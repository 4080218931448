import React from 'react';
import { connect } from 'react-redux';
import { hideFlashMessage } from '../../actions';
import Portal from '../popups/Portal';
import Message from './Message';

function FlashMessage({ flashMessages, hideFlashMessage }) {
  return (
    <Portal>
      {flashMessages.map((message) => (
        <Message
          key={message.id}
          message={message}
          hideFlashMessage={hideFlashMessage}
        />
      ))}
    </Portal>
  );
}

export default connect(
  (state) => ({
    flashMessages: state.flashMessages,
  }),
  { hideFlashMessage },
)(FlashMessage);
