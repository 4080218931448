import { Button, Form, Input, Typography } from 'antd';
import 'firebase/compat/auth';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { MdCheckCircle } from 'react-icons/md';
import { connect } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import { loginUser, logoutUser } from '../actions';
import LoadingIndicator from '../components-new/base/loadingIndicator/LoadingIndicator';
import brandLogo from '../images/logo_black_new.png';
import { FORECAST_API_URL } from '../services/firebaseApi';

const { Title, Text } = Typography;

const CreatePassword = ({ loginUserAction, logoutUserAction }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'dashboardPassword',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const tokenCode = searchParams.get('code');

  const [form] = Form.useForm();

  const fetchUserEmail = async ({ tokenCode, password }) => {
    if (isNil(password)) return;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: tokenCode,
        password: password,
      }),
    };

    setIsLoading(true);
    const response = await fetch(
      `${FORECAST_API_URL}/user/create-password`,
      requestOptions,
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data?.error);
    }

    return { email: data?.userEmail, password };
  };

  const onSubmitNewPassword = async (values) => {
    try {
      const { email, password } = await fetchUserEmail({
        tokenCode,
        password: values?.confirmPassword,
      });

      await logoutUserAction();
      await loginUserAction({ email, password });
      navigate('/');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!tokenCode) {
      navigate('/');
    }
    if (error) {
      form.setFields([
        {
          name: 'submitButton',
          errors: [error || t('invalidTokenMessage')],
        },
      ]);
    }
  }, [tokenCode, error, form, t]);

  return (
    <div className="login__bgwrapper fade">
      <Helmet>
        <title>{t('settingPasswordTitle')}</title>
      </Helmet>
      <div className="tw-pt-20 tw-w-full">
        <img
          className="brand-image tw-block tw-items-center tw-mb-10"
          src={brandLogo}
          alt="chaosarchitects_brand_logo_white"
        />
        <div className="tw-px-8 tw-p-2 tw-flex tw-flex-col tw-m-auto tw-max-w-md tw-border tw-border-solid tw-border-gray-200 tw-bg-white tw-shadow-xl">
          <Title className="tw-mt-8 tw-text-center" level={4}>
            {t('createPasswordTitle')}
          </Title>
          <Form
            form={form}
            className="tw-w-full tw-items-center"
            name="set-password"
            onFinish={onSubmitNewPassword}
            style={{ marginTop: 16 }}
            scrollToFirstError
          >
            <Form.Item
              name="password"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || value?.length < 8) {
                      return Promise.reject(new Error(t('atLeast8Characters')));
                    }
                    if (value.search(/[a-z]/) < 0) {
                      return Promise.reject(
                        new Error(t('atLeastLowercaseCharacters')),
                      );
                    }
                    if (value.search(/[A-Z]/) < 0) {
                      return Promise.reject(
                        new Error(t('atLeastUppercaseCharacters')),
                      );
                    }
                    if (value.search(/[0-9]/) < 0) {
                      return Promise.reject(
                        new Error(t('atLeastOneDigitCharacters')),
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password
                size="large"
                placeholder={t('passwordPlaceholder')}
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t('confirmPassword'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('passwordMismatch')));
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                placeholder={t('confirmPasswordPlaceholder')}
              />
            </Form.Item>

            <Form.Item name="submitButton">
              {isLoading ? (
                <LoadingIndicator positionAbsoluteCenter size={26} />
              ) : (
                <Button
                  className="hover:!tw-bg-red-400 tw-w-full"
                  type="primary"
                  htmlType="submit"
                >
                  <Text className="tw-font-semibold">
                    {t('setPasswordButton')}
                  </Text>
                </Button>
              )}
            </Form.Item>
          </Form>
          <ul className="tw-p-0 tw-list-none ">
            <li>
              <Text>
                <MdCheckCircle /> {t('containAtLeastLettersCheck')}
              </Text>
            </li>
            <li>
              <Text>
                <MdCheckCircle /> {t('containUppercaseCheck')}
              </Text>
            </li>
            <li>
              <Text>
                <MdCheckCircle /> {t('containLowercaseCheck')}
              </Text>
            </li>
            <li>
              <Text>
                <MdCheckCircle /> {t('containNumberCheck')}
              </Text>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default connect(null, {
  loginUserAction: loginUser,
  logoutUserAction: logoutUser,
})(CreatePassword);
