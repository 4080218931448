import React from 'react';
import { useTranslation } from 'react-i18next';

import { ColorWrapper, messageIcons } from '../flashMessage/Message';
import useInternetStatus, { STATUS } from '../hooks/useInternetStatus';
import Portal from '../popups/Portal';
import FadeAnimation from '../popups/fadeAnimation';

function InternetNotification() {
  const status = useInternetStatus();
  const [showNotification, setShowNotification] = React.useState(false);
  const isMounted = React.useRef(false);

  const { t } = useTranslation('', { keyPrefix: 'components' });

  React.useEffect(() => {
    if (!isMounted.current) {
      return;
    }
    setShowNotification(true);
    let timerRef;
    if (status === STATUS.online) {
      timerRef = setTimeout(() => {
        setShowNotification(false);
      }, 2000);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timerRef);
    };
  }, [status]);

  React.useEffect(() => {
    isMounted.current = true;
  }, []);

  function renderIcon() {
    const Icon =
      status === STATUS.online ? messageIcons.success : messageIcons.warning;

    return <Icon color="#fff" className="mr-2" />;
  }

  const message =
    status === STATUS.online
      ? t('internetOnlineMessage')
      : t('internetOfflineMessage');

  return (
    <Portal>
      <FadeAnimation id="ïnternet-notification" show={showNotification}>
        <ColorWrapper
          variant={status === STATUS.online ? 'success' : 'warning'}
        >
          <>
            {renderIcon()}
            <p className="text-s text-light m-0">{message}</p>
          </>
        </ColorWrapper>
      </FadeAnimation>
    </Portal>
  );
}

export default InternetNotification;
