import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import emailSentIcon from '../images/icons/email-sent.svg';
import brandLogo from '../images/logo_black_new.png';
import { useNavigate } from 'react-router-dom';

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    emailError: '',
    loading: false,
    isError: false,
    success: false,
  });

  const { email, emailError, isError, success, loading } = state;

  const onChange = ({ target: { name, value } }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      emailError: '',
      isError: false,
    }));
  };

  const validateForm = () => {
    const regEx =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let isErr = false;
    const errs = { emailError: '' };
    if (regEx.test(String(email).toLowerCase()) === false) {
      isErr = true;
      errs.emailError = 'Your email is not valid';
    }
    if (!email.length) {
      isErr = true;
      errs.emailError = 'Email is required';
    }

    setState((prevState) => ({ ...prevState, ...errs }));

    return isErr;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (!errors) {
      setState((prevState) => ({ ...prevState, loading: true }));

      await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setState((prevState) => ({
            ...prevState,
            success: true,
            loading: false,
          }));
        })
        .catch(() => {
          setState((prevState) => ({
            ...prevState,
            isError: true,
            loading: false,
          }));
        });
    }
  };

  const handleSuccess = (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, success: false }));
    navigate('/');
  };

  const errors = emailError ? 'error' : '';
  const errorClasses = `loginform ${errors}`;

  return (
    <div className="login__bgwrapper">
      <Helmet>
        <title>Password recovery - CHAOSarchitects</title>
      </Helmet>
      <div className="w-full">
        <img
          className="brand-image text-center mb-16"
          src={brandLogo}
          alt="chaosarchitects_brand_logo_white"
        />
        <br />
        <div className="loginform__wrapper dropshadow-5 br-5 py-2 scale">
          <form className={errorClasses}>
            {success ? (
              <div className="fade">
                <h2 className="form-title text-center fade animate--5s">
                  Email sent
                </h2>
                <img className="notifi-icon" src={emailSentIcon} alt="" />
                <span className="notifi-text">
                  Check your email address and,
                  <br />
                  follow the instruction.
                </span>
                <button className="btn__default br-5" onClick={handleSuccess}>
                  GOT IT!
                </button>
              </div>
            ) : (
              <div className="fade">
                <h2 className="form-title text-center">Reset password</h2>
                <input
                  className="loginform__input br-5"
                  name="email"
                  value={email}
                  onChange={onChange}
                  type="email"
                  placeholder="* Your email"
                  data-test-id="input-email"
                />
                {emailError && (
                  <span className="text-error text-s fade">{emailError}</span>
                )}
                <br />
                <br />
                <span className="notifi-text">
                  Enter your email address,
                  <br />
                  and we'll send you a email.
                </span>
                {loading ? (
                  <span className="loading" />
                ) : (
                  <button
                    className="btn__default br-5"
                    onClick={handleSubmit}
                    data-test-id="button-send"
                  >
                    SEND
                  </button>
                )}
              </div>
            )}
          </form>
          <br />
          {isError && (
            <span className="text-center text-error text-s mb-1 fade">
              Something went wrong! Please try again.
            </span>
          )}
          <br />
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;
