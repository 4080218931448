import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

const GoogleAnalysisPageTracker = ({ children, currentUser }) => {
  const location = useLocation();

  const userInfo = useMemo(() => {
    if (currentUser?.info) {
      const { userId, companyId, companyName, customerSegment } =
        currentUser.info;

      return {
        user_id: userId,
        user_properties: {
          company_id: companyId,
          company_name: companyName,
          customer_segment: customerSegment,
        },
      };
    }
    return {};
  }, [currentUser?.info]);

  const trackPage = useCallback(
    (page) => {
      ReactGA.set({
        page,
        ...userInfo,
      });
    },
    [userInfo],
  );

  useEffect(
    () => trackPage(location?.pathname),
    [location?.pathname, trackPage],
  );

  return children;
};

GoogleAnalysisPageTracker.propType = {
  currentUser: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

GoogleAnalysisPageTracker.defaultProps = {
  currentUser: null,
};

const mapStateToProps = (state) => ({
  currentUser: state.user,
});

export default connect(mapStateToProps)(GoogleAnalysisPageTracker);
