import { produce } from 'immer';
import { has, keys } from 'ramda';

import {
  CLEAR_FORECAST_DATA,
  CLEAR_OFFICE_MARKET,
  CLEAR_POINT_OF_INTEREST,
  CLEAR_PROPERTY_MARKET,
  CLEAR_SELECTED_GRID_SUPPORTING_INFO,
  GET_ADVANCED_HOUSEHOLD_TYPES_DATA,
  GET_AREA_DEVELOPMENT,
  GET_FOOTFALL_GRID_SUPPORTING_INFO,
  GET_FORECAST_DATA,
  GET_FORECAST_DATA_ASYNC,
  GET_FORECAST_DATA_ERROR,
  GET_OFFICE_MARKET,
  GET_POINT_OF_INTEREST,
  GET_POPULATION_STRUCTURE_DATA,
  GET_PROPERTY_MARKET,
  GET_RESIDENTIAL_DEMAND_SUPPLY,
  GET_SERVICE_MIX_DATA,
  GET_TARGET_CUSTOMER_SEARCH,
  GET_TARGET_CUSTOMER_SEARCH_HOUSEHOLD,
  REMOVE_DASHBOARD_DATA,
  SET_DASHBOARD_MAP_BOUND,
  SET_POINT_OF_INTEREST_VISIBLE,
  UPDATE_FOOTFALL_DATA,
  UPDATE_HOUSEHOLD_TYPES_SELECTED_POSTCODE,
  UPDATE_POPULATION_STRUCTURE_SELECTED_POSTCODE,
  UPDATE_REACHABILITY_SELECTION,
  UPDATE_SERVICE_MIX_DATA,
} from '../actions/types';
import { indicators } from '../pages/forecast/maps/constants';

/* eslint default-case: "error" */

const forecastingDataReducer = produce(
  (draft, action) => {
    switch (action.type) {
      case GET_FORECAST_DATA:
        keys(action.payload.res).forEach((dataKey) => {
          draft[action.payload.key][dataKey] = action.payload.res[dataKey];
        });
        break;

      case GET_TARGET_CUSTOMER_SEARCH:
        draft[action.payload.key] = {
          ...(action.payload.data && action.payload.data),
          meta: action.payload.meta,
        };
        break;

      case GET_TARGET_CUSTOMER_SEARCH_HOUSEHOLD:
        draft[action.payload.key] = {
          ...(action.payload.data && action.payload.data),
          meta: action.payload.meta,
        };
        break;

      case GET_POPULATION_STRUCTURE_DATA:
        if (!draft[indicators['Population structure']]) {
          draft[indicators['Population structure']] = {};
        }

        draft[indicators['Population structure']][action.payload.dataKey] = {
          ...(action.payload.data && action.payload.data),
          meta: action.payload.meta,
        };
        break;

      case UPDATE_POPULATION_STRUCTURE_SELECTED_POSTCODE:
        draft[indicators['Population structure']].selectedPostcodeData = {
          ...(action.payload.data && action.payload.data),
        };
        break;

      case GET_ADVANCED_HOUSEHOLD_TYPES_DATA:
        draft[indicators['Advanced household types']][action.payload.dataKey] =
          {
            ...(action.payload.data && action.payload.data),
            meta: action.payload.meta,
          };
        break;

      case UPDATE_HOUSEHOLD_TYPES_SELECTED_POSTCODE:
        draft[indicators['Advanced household types']].selectedPostcodeData = {
          ...(action.payload.data && action.payload.data),
          meta: action.payload.meta,
        };
        break;

      // footfall
      case UPDATE_FOOTFALL_DATA:
        if (!draft[indicators.Footfall]) {
          draft[indicators.Footfall] = {};
        }
        // eslint-disable-next-line no-case-declarations
        const currData =
          action.payload.data ||
          draft[indicators.Footfall][action.payload.dataKey] ||
          {};
        draft[indicators.Footfall][action.payload.dataKey] = {
          ...currData,
          meta: action.payload.meta,
        };
        break;

      case GET_FOOTFALL_GRID_SUPPORTING_INFO:
        draft[indicators.Footfall].selectedGridSupportingInfo =
          action.payload.data;
        break;

      // service mix
      case UPDATE_REACHABILITY_SELECTION:
        draft[indicators['Services mix']] = {
          ...draft[indicators['Services mix']],
          ...action.payload,
        };
        break;

      case UPDATE_SERVICE_MIX_DATA:
        // eslint-disable-next-line no-case-declarations
        let { data } = action.payload;
        data = data?.data || data;
        draft[indicators['Services mix']] = {
          ...draft[indicators['Services mix']],

          [action.payload.dataKey]: {
            ...(draft?.[indicators['Services mix']]?.[action.payload.dataKey] ||
              {}),
            meta: action.payload.meta,
            ...(data && { data }),
          },
        };
        break;

      case GET_SERVICE_MIX_DATA:
        draft[indicators['Services mix']][action.payload.dataKey] = {
          ...(action.payload.data && action.payload.data),
          meta: action.payload.meta,
        };
        break;

      case GET_RESIDENTIAL_DEMAND_SUPPLY:
        draft[indicators['Residential demand-supply']][action.payload.dataKey] =
          {
            ...(action.payload.data &&
              !action.payload.meta.cacheKey &&
              action.payload.data),
            meta: action.payload.meta,
          };

        if (!draft[indicators['Residential demand-supply']].cache) {
          draft[indicators['Residential demand-supply']].cache = {};
        }

        if (action.payload.meta.cacheKey && action.payload.data) {
          draft[indicators['Residential demand-supply']].cache[
            action.payload.meta.cacheKey
          ] = action.payload.data;
        }

        break;

      case CLEAR_SELECTED_GRID_SUPPORTING_INFO:
        draft[indicators['Residential demand-supply']].selectedGrid = null;
        break;

      // property market
      case GET_PROPERTY_MARKET: {
        const rootKey = indicators['Property market'];

        if (!has(rootKey, draft)) {
          draft[rootKey] = {};
        }

        if (action.payload.data) {
          draft[rootKey][action.payload.dataKey] = action.payload.data;
          draft[rootKey][action.payload.dataKey].meta = action.payload.meta;
        } else {
          if (!has(action.payload.dataKey, draft[rootKey])) {
            draft[rootKey][action.payload.dataKey] = {};
          }

          draft[rootKey][action.payload.dataKey].meta = action.payload.meta;
        }
        break;
      }

      case CLEAR_PROPERTY_MARKET: {
        const root = indicators['Property market'];
        if (draft?.[root]?.[action.payload.dataKey]) {
          delete draft[root][action.payload.dataKey];
        }
        break;
      }

      // office market index
      case GET_OFFICE_MARKET:
        if (!has(indicators['Office market'], draft)) {
          draft[indicators['Office market']] = {};
        }
        if (action.payload.data) {
          draft[indicators['Office market']][action.payload.dataKey] =
            action.payload.data;
          draft[indicators['Office market']][action.payload.dataKey].meta =
            action.payload.meta;
        } else {
          if (
            !has(action.payload.dataKey, draft[indicators['Office market']])
          ) {
            draft[indicators['Office market']][action.payload.dataKey] = {};
          }

          draft[indicators['Office market']][action.payload.dataKey].meta =
            action.payload.meta;
        }
        break;

      case CLEAR_OFFICE_MARKET: {
        const officeMarketRoot = indicators['Office market'];
        if (draft?.[officeMarketRoot]?.[action.payload.dataKey]) {
          delete draft[officeMarketRoot][action.payload.dataKey];
        }
        break;
      }

      // area development
      case GET_AREA_DEVELOPMENT: {
        const rootKey = indicators['Area development'];

        if (!has(rootKey, draft)) {
          draft[rootKey] = {};
        }

        if (action.payload.data) {
          draft[rootKey][action.payload.dataKey] = action.payload.data;
          draft[rootKey][action.payload.dataKey].meta = action.payload.meta;
        } else {
          if (!has(action.payload.dataKey, draft[rootKey])) {
            draft[rootKey][action.payload.dataKey] = {};
          }

          draft[rootKey][action.payload.dataKey].meta = action.payload.meta;
        }
        break;
      }

      // common actions
      case GET_FORECAST_DATA_ASYNC:
        draft[action.payload.key] = draft[action.payload.key] || {};
        draft[action.payload.key].async = action.payload.async;
        break;

      case GET_FORECAST_DATA_ERROR:
        draft[action.payload.key] = draft[action.payload.key] || {};
        draft[action.payload.key].error = action.payload.error;
        break;

      case SET_DASHBOARD_MAP_BOUND:
        if (!draft[action.payload.key]) {
          draft[action.payload.key] = {};
        }
        draft[action.payload.key].mapBound = action.payload.data;
        break;

      case REMOVE_DASHBOARD_DATA:
        if (draft[action.payload]) {
          delete draft[action.payload];
        }
        break;

      case GET_POINT_OF_INTEREST:
        draft['Point of interest'] = {
          ...(action.payload.data && action.payload.data),
          meta: action.payload.meta,
        };
        break;

      case CLEAR_POINT_OF_INTEREST: {
        if (draft?.['Point of interest']) {
          delete draft['Point of interest'];
        }
        break;
      }

      case SET_POINT_OF_INTEREST_VISIBLE: {
        draft.isPointOfInterestVisible = action.payload;
        break;
      }

      case CLEAR_FORECAST_DATA:
        return {};

      // No Default
    }
  },
  { isPointOfInterestVisible: false },
);

export default forecastingDataReducer;
