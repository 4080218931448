import {
  SET_AREA_SELECTION_MODE,
  SET_HEXAGON_IDS,
  SET_SEARCH_AREA_OUTLINE_GEOMETRY,
} from './types';

export const setHexagonIds = (data) => (dispatch) =>
  dispatch({
    type: SET_HEXAGON_IDS,
    payload: data,
  });

export const setSelectionMode = (data) => (dispatch) =>
  dispatch({
    type: SET_AREA_SELECTION_MODE,
    payload: data,
  });

export const setSearchAreaOutlineGeometry = (data) => (dispatch) =>
  dispatch({
    type: SET_SEARCH_AREA_OUTLINE_GEOMETRY,
    payload: data,
  });
