import {
  SET_AREA_SELECTION_MODE,
  SET_HEXAGON_IDS,
  SET_SEARCH_AREA_OUTLINE_GEOMETRY,
} from '../actions/types';
import { AREA_SELECTION_MODES } from '../dashboard/areaSelection/helpers';

const initialState = {
  selectionMode: AREA_SELECTION_MODES.selectAreaBySearch,
  searchAreaOutlineGeometry: null,
  selectedAreaH3Grid: null,
};

const areaSelectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HEXAGON_IDS:
      return {
        ...state,
        selectedAreaH3Grid: action.payload,
      };

    case SET_AREA_SELECTION_MODE:
      return {
        ...state,
        selectionMode: action.payload,
      };

    case SET_SEARCH_AREA_OUTLINE_GEOMETRY:
      return {
        ...state,
        searchAreaOutlineGeometry: action.payload,
      };

    default:
      return state;
  }
};

export default areaSelectionReducer;
