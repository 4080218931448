import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';

const uploadImage = (imageFile, type, companyId) =>
  new Promise((resolve) => {
    const storageRef = firebase
      .storage()
      .ref('/images/company/')
      .child(`${type}${companyId}`);
    const task = storageRef.put(imageFile);
    task.on('state_changed', (snapshot) => {
      const completed = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      if (completed === 100) {
        storageRef.getDownloadURL().then((urlPath) => {
          resolve(urlPath);
        });
      }
    });
  });

export const updateCompany = async (companyData, companyId) => {
  const {
    companyImage,
    touchedCompanyImage,
    companyLogo,
    touchedCompanyLogo,
    companyName,
    companyDetail,
    location,
    emailAddress,
    url,
    phoneNumber,
    businessCategory,
  } = companyData;

  const newData = {
    name: companyName,
    description: companyDetail,
    address: location,
    email: emailAddress,
    url,
    phone: phoneNumber,
    category: businessCategory,
    logo_img: companyLogo,
    cover_img: companyImage,
  };
  if (touchedCompanyImage && touchedCompanyLogo) {
    const coverUrlPromise = uploadImage(companyImage, 'cover_img', companyId);
    const logoUrlPromise = uploadImage(companyLogo, 'logo_img', companyId);
    const [coverUrl, logoUrl] = await Promise.all([
      coverUrlPromise,
      logoUrlPromise,
    ]);
    await firebase
      .database()
      .ref(`company/${companyId}`)
      .update({
        ...newData,
        logo_img: logoUrl,
        cover_img: coverUrl,
      });
  } else if (touchedCompanyImage) {
    const coverUrl = await uploadImage(companyImage, 'cover_img', companyId);
    await firebase
      .database()
      .ref(`company/${companyId}`)
      .update({
        ...newData,
        cover_img: coverUrl,
      });
  } else if (touchedCompanyLogo) {
    const logoUrl = await uploadImage(companyLogo, 'logo_img', companyId);
    await firebase
      .database()
      .ref(`company/${companyId}`)
      .update({
        ...newData,
        logo_img: logoUrl,
      });
  } else {
    await firebase
      .database()
      .ref(`company/${companyId}`)
      .update({
        ...newData,
      });
  }
};

export const updateUser = async (userData) => {
  const currentUser = await firebase.auth().currentUser;

  return firebase
    .database()
    .ref(`users/${currentUser.uid}`)
    .update({ ...userData, uid: currentUser.uid });
};

export const getAppVersionRef = () => firebase.database().ref(`/app_version`);

export const getReleaseRef = () => firebase.database().ref(`/release/`);

export const uploadReportImage = async (fileName, file) => {
  const storage = firebase.storage();
  const storageRef = storage.ref('/images/company/reporting/');
  const fileRef = storageRef.child(fileName);

  const snapshot = await fileRef.put(file);
  return snapshot.ref.getDownloadURL();
};

export const deleteReportImage = async (fileName) => {
  const storage = firebase.storage();
  const storageRef = storage.ref('/images/company/reporting/');
  const fileRef = storageRef.child(fileName);

  await fileRef.delete();
};