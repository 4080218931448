import { keys } from 'ramda';
import formatDecimal from '../../../helpers/formatDecimal';
import {
  GENERATION_AGE_GROUPS,
  GENERATION_AGE_GROUPS_SE,
  MODIFIED_AGE_GROUPS,
  MODIFIED_AGE_GROUPS_SE,
} from './filters';
import { countries } from '../../../helpers/country';

export const countryToAgeGroupMapping = (country) => {
  switch (country) {
    case countries.SWEDEN:
      return MODIFIED_AGE_GROUPS_SE;
    default:
      return MODIFIED_AGE_GROUPS;
  }
};

export const countryToGenerationMapping = (country) => {
  switch (country) {
    case countries.SWEDEN:
      return GENERATION_AGE_GROUPS_SE;
    default:
      return GENERATION_AGE_GROUPS;
  }
};

const sumUpAgeCategories = (values, country, isGeneration = false) => {
  const currentGrouping = isGeneration
    ? countryToGenerationMapping(country)
    : countryToAgeGroupMapping(country);

  return keys(currentGrouping).reduce((groups, group) => {
    currentGrouping[group].forEach((age) => {
      groups[group] = {
        absolute:
          Number(groups[group]?.absolute || 0) +
          Number(values[age]?.absolute || 0),
        percentage: formatDecimal(
          Number(groups[group]?.percentage || 0) +
            Number(values[age]?.percentage || 0),
          2,
        ),
      };
    });
    return groups;
  }, {});
};

const aggregateAgeGroup = (data, country, isGeneration) =>
  Object.keys(data).reduce(
    (t, year) => ({
      ...t,
      [year]: sumUpAgeCategories(data[year], country, isGeneration),
    }),
    {},
  );

export const sumUpToAgeGroups = (values, country) =>
  sumUpAgeCategories(values, country, false);

export const sumUpToGenerations = (values, country) =>
  sumUpAgeCategories(values, country, true);

export const aggregateAgeCategories = (values, country) =>
  aggregateAgeGroup(values, country, false);

export const aggregateGenerations = (values, country) =>
  aggregateAgeGroup(values, country, true);

export const aggregatePopupAge = (allAgeGroups, country) => {
  const ageGroups = countryToAgeGroupMapping(country);

  return Object.keys(ageGroups).reduce(
    (aggregatedAgeGroups, ageGroup) => ({
      ...aggregatedAgeGroups,
      [ageGroup]: ageGroups[ageGroup].reduce(
        (total, age) => total + allAgeGroups[age],
        0,
      ),
    }),
    {},
  );
};
