import cx from 'classnames';
import React from 'react';

import mapPlaceHolder from '../../../images/map-placeholder.png';
import supportingChartPlaceholder from '../../../images/supporting-charts-placeholder.png';
import HubSpotContactSalesForm from './HubSpotContactSalesForm';
import styles from './_ForecastUpgradeModal.module.scss';

function ForecastUpgradeModal() {
  const [showHubSpotForm, setShowHubSpotForm] = React.useState(true);
  return (
    <div className={cx(styles.root, 'forecast-container flex fade')}>
      <div className={cx('side-content', styles.navBarOffset)}>
        <img
          src={supportingChartPlaceholder}
          alt="side-content-placeholder"
          className={styles.supportingChartsPlaceholder}
        />
      </div>
      <div className="map-content">
        <img
          src={mapPlaceHolder}
          alt="map-placeholder"
          className={styles.mapPlaceHolder}
        />
      </div>
      <HubSpotContactSalesForm
        featureName="Insights"
        showHubSpotForm={showHubSpotForm}
        setShowHubSpotForm={setShowHubSpotForm}
        disableClose={true}
        wrapperStyle={{ marginLeft: 50, boxSixing: 'border-box' }}
      />
    </div>
  );
}

export default ForecastUpgradeModal;
