import { Button, ConfigProvider, Space, notification } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdUpdate } from 'react-icons/md';

import { CHAOS_THEME } from '../App';
import { gaEvents, sendGACommonEvent } from '../helpers/gaTracker';

const ReleaseNotificationView = ({ releaseTimeUTC, releaseNoteUrl }) => {
  const { t } = useTranslation('', {
    keyPrefix: 'releaseNotification',
    useSuspense: false,
  });
  const [notificationInstance, contextHolder] = notification.useNotification();
  useEffect(() => {
    const onCloseReleaseMessage = () => {
      notificationInstance.destroy('releaseNotification');
    };
    const onOpenReleaseDetails = () => {
      sendGACommonEvent(gaEvents.OPEN_RELEASE_NOTE, {
        release_time: releaseTimeUTC,
        release_link: releaseNoteUrl,
      });
      window.open(releaseNoteUrl, '_blank');
      notificationInstance.destroy('releaseNotification');
    };
    const btn = (
      <Space direction="horizontal">
        <Button
          size="middle"
          type="default"
          onClick={onCloseReleaseMessage}
          data-test-id="close-message-button"
        >
          {t('toCloseReleaseMessage')}
        </Button>
        {releaseNoteUrl && (
          <Button size="middle" type="primary" onClick={onOpenReleaseDetails}>
            {t('toViewReleaseNote')}
          </Button>
        )}
      </Space>
    );
    notificationInstance.open({
      key: 'releaseNotification',
      message: t('releaseMessageTitle'),
      description: t('releaseMessageDescription', {
        releaseTime: dayjs(releaseTimeUTC).format('h:mm A'),
      }),
      icon: <MdUpdate color="#face67" />,
      duration: null,
      style: { border: '1px solid #face67' },
      btn,
      placement: 'top',
      className: 'release-notification-view',
    });
  }, [releaseTimeUTC, releaseNoteUrl, t, notificationInstance, contextHolder]);
  return <ConfigProvider theme={CHAOS_THEME}>{contextHolder}</ConfigProvider>;
};

ReleaseNotificationView.defaultProps = {
  releaseTimeUTC: null,
  releaseNoteUrl: null,
};

ReleaseNotificationView.propTypes = {
  releaseTimeUTC: PropTypes.string,
  releaseNoteUrl: PropTypes.string,
};
export default ReleaseNotificationView;
