import { SHOW_FLASH_MESSAGE, HIDE_FLASH_MESSAGE } from './types';

export const showFlashMessage = (message) => ({
  type: SHOW_FLASH_MESSAGE,
  payload: message,
});

export const hideFlashMessage = (messageId) => ({
  type: HIDE_FLASH_MESSAGE,
  payload: messageId,
});
