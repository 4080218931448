import {
  ADD_TO_REPORT_RECENTLY_USED_INSIGHTS,
  REFRESH_REPORT_STATE,
  SET_IS_REPORT_AREA_SELECTION_ENABLED,
  SET_SELECTED_EDITOR,
  SET_SELECTED_MENU_TAB,
  UPDATE_REPORT,
  UPDATE_REPORT_COMPONENT_PROPS,
} from '../actions/types';
import {
  REPORT_MENU_TABS,
  REPORT_ORIENTATION,
  getNewEmptyPageElement,
  getRecentlyUsedInsightsFromStorage,
  saveRecenltyUsedInsightsInStorage,
  updateComponentProps,
} from '../reports-v2/helpers';

const initialState = {
  reportName: null,
  reportOrientation: REPORT_ORIENTATION.undefined,
  project: '',
  isPublished: false,
  reportFontFamily: 'Roboto',
  reportHeaderEnabled: true,
  reportDescriptionEnabled: true,
  pages: [getNewEmptyPageElement()],
  currentReportScrollTop: 0,
  selectedItem: null,
  masterMap: undefined,
  masterMapBound: undefined,
  draggingItem: null,
  recentlyUsedInsights: [...getRecentlyUsedInsightsFromStorage()],
  isLoading: false,
  selectedMenuTab: REPORT_MENU_TABS.setup,
  isReportAreaSelectionEnabled: false,
  searchAdvancedAreaPolygon: null,
  selectedEditor: null,
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REPORT:
      return { ...state, ...action.payload };

    case UPDATE_REPORT_COMPONENT_PROPS:
      return {
        ...state,
        pages: updateComponentProps({ pages: state.pages, ...action.payload }),
      };

    case ADD_TO_REPORT_RECENTLY_USED_INSIGHTS:
      const insightsHistory = [
        action?.payload?.insightId,
        ...state?.recentlyUsedInsights,
      ];
      const uniqueListOfInsights = [...new Set(insightsHistory)];
      const topTenInsights = uniqueListOfInsights?.slice(0, 10);
      saveRecenltyUsedInsightsInStorage(topTenInsights);

      return {
        ...state,
        recentlyUsedInsights: topTenInsights,
      };

    case REFRESH_REPORT_STATE:
      return {
        ...initialState,
        recentlyUsedInsights: state?.recentlyUsedInsights,
        masterMapBound: state?.masterMapBound,
        masterMap: state?.masterMap,
      };

    case SET_SELECTED_MENU_TAB:
      return {
        ...state,
        selectedMenuTab: action.payload,
      };

    case SET_IS_REPORT_AREA_SELECTION_ENABLED:
      return {
        ...state,
        isReportAreaSelectionEnabled: action.payload,
      };

    case SET_SELECTED_EDITOR:
      return {
        ...state,
        selectedEditor: action.payload,
      };

    default:
      return state;
  }
};

export default reportReducer;