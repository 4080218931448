import { GET_USER_ROLES } from '../actions/types';

const DEFAULT_STATE = {};
export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case GET_USER_ROLES:
      return action.payload;
    default:
      return state;
  }
}
