import * as Sentry from '@sentry/browser';
import axios from 'axios';
import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { jwtDecode } from 'jwt-decode';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  GLOBAL_FEATURES_API_URL,
  getGlobalFeatures,
  getUserInfo,
  logoutUser,
} from './actions';
import { LOGIN_USER } from './actions/types';
import LoaderText from './components/popups/loaderText';
import userLoginHistory from './helpers/userLoginHistory';
import ReleaseNotificationView from './notification/ReleaseNotificationView';
import {
  getAppVersionRef,
  getReleaseRef,
  updateUser,
} from './services/firebaseHelpers';
import store from './store/store';

export const FORECAST_API_URL = process.env.REACT_APP_FORECAST_API_URL;

const LOADING_TIMEOUT = 15 * 1000;
const unauthorizedInterceptor = async (error) => {
  if (
    error?.response?.status === 401 &&
    // prevents global features from being called over and over again
    !error.config.url.endsWith(GLOBAL_FEATURES_API_URL)
  ) {
    await getGlobalFeatures(store.dispatch);
  }
  return Promise.reject(error);
};

const refreshTokenInterceptor = (currentUser) => async (config) => {
  const token = config?.headers?.Authorization;

  const isBackendRequest = config.url.startsWith(FORECAST_API_URL);

  if (isBackendRequest && currentUser) {
    try {
      const isTokenExpired = token
        ? jwtDecode(token)?.exp <= dayjs().unix()
        : true;

      if (isTokenExpired) {
        const newlyGeneratedToken = await currentUser.getIdToken(true);
        localStorage.setItem('token', newlyGeneratedToken);

        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = newlyGeneratedToken;
      }
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setTag('component', 'AuthHandler');
        scope.setLevel('error');
        scope.setExtra('url', config?.url);
        scope.setExtra('is online', navigator?.onLine);
        Sentry.captureException(e);
      });
    }
  }
  return config;
};

function AuthHandler({ children }) {
  const { t } = useTranslation('', {
    keyPrefix: 'dashboardView',
    useSuspense: false,
  });
  const [currentUser, setCurrentUser] = React.useState(null);
  const [releaseTimeUTC, setReleaseTimeUTC] = React.useState(null);
  const [releaseNoteUrl, setReleaseNoteUrl] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingTimeout, setIsLoadingTimeout] = React.useState(false);
  React.useEffect(() => {
    const refreshTokenInterceptorId = axios.interceptors.request.use(
      refreshTokenInterceptor(currentUser),
    );
    const unauthorizedInterceptorId = axios.interceptors.response.use(
      (response) => response,
      unauthorizedInterceptor,
    );
    return () => {
      axios.interceptors.request.eject(refreshTokenInterceptorId);
      axios.interceptors.response.eject(unauthorizedInterceptorId);
    };
  }, [currentUser]);

  const fetchUserResources = async () => {
    await getGlobalFeatures(store.dispatch);
    return getUserInfo(store.dispatch);
  };

  React.useEffect(() => {
    const appVersionRef = getAppVersionRef();
    const releaseRef = getReleaseRef();

    async function initiateAuthentication() {
      const loadingTimeoutRef = setTimeout(() => {
        setIsLoadingTimeout(true);
      }, LOADING_TIMEOUT);

      try {
        setIsLoading(true);
        const token = await currentUser.getIdToken(true);
        localStorage.setItem('token', token);
        const userData = await firebase
          .database()
          .ref(`users/${currentUser.uid}`)
          .once('value')
          .then((snapshot) => snapshot.val());
        // handle simultaneous log in
        const localUsid = localStorage.getItem('usid');
        const usidRef = firebase
          .database()
          .ref(`users/${currentUser.uid}`)
          .child('usid');

        const isE2eTestUser =
          userData.email === 'chaos-test@chaosarchitects.com';

        // disable this feature for the E2E test user (see README.md for more information)
        if (!isE2eTestUser) {
          usidRef.on('value', async (snap) => {
            if (snap.val() !== localUsid) {
              store.dispatch(logoutUser());
            }
          });
        }

        const appVersion = await appVersionRef
          .once('value')
          .then((snapshot) => snapshot.val());
        releaseRef.on('value', (snapshot) => {
          // eslint-disable-next-line camelcase
          const { link, time_utc } = snapshot.val();
          // eslint-disable-next-line camelcase
          if (time_utc) {
            setReleaseTimeUTC(time_utc);
          }
          if (link) {
            setReleaseNoteUrl(link);
          }
        });

        localStorage.setItem('app_version', appVersion);

        // Refresh app on version update.
        appVersionRef.on('value', (snapshot) => {
          const latestAppVersion = snapshot.val();
          const existingAppVersion = localStorage.getItem('app_version');
          if (
            latestAppVersion !== null &&
            existingAppVersion !== latestAppVersion
          ) {
            window.location.reload();
          }
        });

        const { history, usid } = userData;
        if (usid !== localUsid) {
          store.dispatch(logoutUser());
          return;
        }

        // update user status on app initial load
        const historyData = userLoginHistory(history);
        await updateUser({
          lastLogin: dayjs().format(),
          history: historyData,
        });

        // fetch user, roles, company and projects in redux store
        const userInfo = await fetchUserResources();
        Sentry.setUser({
          email: userInfo?.email,
          userId: userInfo?.userId,
          organizationId: userInfo?.companyId,
        });

        store.dispatch({ type: LOGIN_USER });

        clearTimeout(loadingTimeoutRef);
        setIsLoading(false);
        setIsLoadingTimeout(false);
      } catch (e) {
        // retry authentication
        clearTimeout(loadingTimeoutRef);
        setTimeout(initiateAuthentication, 1000);
      }
    }

    if (currentUser) {
      initiateAuthentication();
    }

    // Unsubscribe from reatlime-database listener on unmount
    return () => {
      appVersionRef.off();
      releaseRef.off();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user?.uid !== currentUser?.uid) {
        setCurrentUser(user);
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => setIsLoadingTimeout(false), [isLoading]);

  const loadingTimeoutHeader = (
    <div className="text-center">
      <h4 className="text-grey-5 tw-whitespace-pre-wrap">
        {t('internetProblemText')}
      </h4>
      <p style={{ fontSize: '0.9rem' }}>{t('keepTryingText')}</p>
    </div>
  );

  const renderLoadingState = () => {
    if (isLoadingTimeout) {
      return (
        <LoaderText
          bgWhite
          header={loadingTimeoutHeader}
          displayText="Authenticating..."
          loaderStyle={{ margin: '1rem' }}
        />
      );
    }
    return <LoaderText displayText="Authenticating..." bgWhite />;
  };

  const isReleaseTime = dayjs().isSame(releaseTimeUTC, 'day');

  return (
    <>
      {isReleaseTime && (
        <ReleaseNotificationView
          releaseTimeUTC={releaseTimeUTC}
          releaseNoteUrl={releaseNoteUrl}
        />
      )}
      {children || null}
      {isLoading && renderLoadingState()}
    </>
  );
}

AuthHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthHandler;
