import * as Sentry from '@sentry/browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'antd/dist/reset.css';
import 'array-flat-polyfill';
import React from 'react';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import AuthHandler from './AuthHandler';
import ErrorBoundary from './components/ErrorBoundary';
import FlashMessage from './components/flashMessage';
import InternetNotification from './components/internetNotification/InternetNotification.jsx';
import { isProduction } from './helpers/extra';
import './i18n';
import './index.scss';
import { initializeFirebaseApp } from './services/firebase';
import store from './store/store';

// Error tracking sdk
Sentry.init({
  dsn: isProduction
    ? 'https://a28f9d4e0592488ba73d58e0552929fb@o391419.ingest.sentry.io/5237501'
    : null,
  environment: process.env.REACT_APP_ENV,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.5,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'The error you provided does not contain a stack trace.',
    // https://github.com/mapbox/mapbox-gl-js/issues/8480
    'AbortError: Fetch is aborted',
    'Error while drawing area selection polygon',
  ],
});

initializeFirebaseApp();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 5 minutes
      staleTime: 5 * 60 * 1000,
      retry: 0,
    },
  },
});

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <AuthHandler>
        <QueryClientProvider client={queryClient}>
          <App />
          <FlashMessage />
          <InternetNotification />
        </QueryClientProvider>
      </AuthHandler>
    </ErrorBoundary>
  </Provider>,
);
