import * as Sentry from '@sentry/browser';
import axios from 'axios';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

import {
  ERROR_CODE_DATA_NOT_AVAILABLE,
  ERROR_CODE_OUTSIDE_OF_TRIAL_AREA,
  ERROR_CODE_UNAUTHORIZED_AREA_ACCESS,
} from '../dashboard/areaSelection/helpers';

export const FORECAST_API_URL = process.env.REACT_APP_FORECAST_API_URL;

const cleanUrlPath = (url) => url.replaceAll('//', '/');

const getHeaderConfig = () => ({
  headers: {
    Authorization: localStorage.getItem('token'),
    Country: localStorage.getItem('Country') || null,
  },
});

const logApiError = (e, body, url, headerConfig) => {
  Sentry.withScope((scope) => {
    scope.setTag('component', 'API Error');
    scope.setLevel('error');

    const token = headerConfig?.headers?.Authorization;
    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        scope.setExtra(
          'has token expired',
          decodedToken?.exp <= dayjs().unix(),
        );
      } catch {
        // invalid token => do nothing for now
      }
    }

    if (body) {
      scope.setExtra('body', JSON.stringify(body));
    }

    scope.setExtra('country', headerConfig?.headers?.Country);
    scope.setExtra('error code', e?.response?.status);
    scope.setExtra('error data', JSON.stringify(e?.response?.data || {}));
    scope.setExtra('window inner width', window?.innerWidth);
    scope.setExtra('window inner height', window?.innerHeight);
    scope.setExtra('is online', navigator?.onLine);
    scope.setExtra('error name', e?.name);
    scope.setExtra('error message', e?.message);

    if (!e.response) {
      // preflights error which indicates a CORS or dropped internet issue
      scope.setExtra(
        'Preflight error',
        'True (indicates a CORS or dropped internet connection issue)',
      );
    }

    Sentry.captureMessage(
      e.message || e.response.statusText || `API error without message: ${url}`,
    );
  });
};

export const firebaseGetApi = async (url, params = {}) => {
  const headerConfig = getHeaderConfig();

  const response = await axios
    .get(`${FORECAST_API_URL}${url}`, {
      params,
      responseType: params?.responseType || undefined,
      ...headerConfig,
    })
    .catch((e) => {
      // If request was canceled
      if (axios.isCancel(e)) {
        return;
      }
      logApiError(e, params, url, headerConfig);
      throw e;
    });
  return response.data;
};

export const firebasePostApi = async (url, data, axiosConfig = {}) => {
  const headerConfig = getHeaderConfig();

  const response = await axios
    .post(`${FORECAST_API_URL}${cleanUrlPath(url)}`, data, {
      ...headerConfig,
      ...axiosConfig,
    })
    .catch((e) => {
      const responseStatus = e?.response?.status;

      // If request was canceled
      if (axios.isCancel(e)) {
        return;
      }

      // Error if user access unauthorized area or area data doesn't have any data
      if (
        [
          ERROR_CODE_DATA_NOT_AVAILABLE,
          ERROR_CODE_UNAUTHORIZED_AREA_ACCESS,
          ERROR_CODE_OUTSIDE_OF_TRIAL_AREA,
        ].includes(responseStatus)
      ) {
        throw e;
      }

      logApiError(e, data, url, headerConfig);
      throw e;
    });
  return response.data;
};

export const firebasePatchApi = async (url, data) => {
  const response = await axios.patch(
    `${FORECAST_API_URL}${cleanUrlPath(url)}`,
    data,
    getHeaderConfig(),
  );
  return response.data;
};

export const firebaseDeleteApi = async (url, params = {}) => {
  const response = await axios.delete(
    `${FORECAST_API_URL}${cleanUrlPath(url)}`,
    { params, ...getHeaderConfig() },
  );
  return response.data;
};
