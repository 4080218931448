import { isNil, reject } from 'ramda';

import { footfallEndpoints, getTimeParams } from '../api/footfallDashboard';
import { flashMessagesVariant } from '../reducers/flashMessage';
import { firebasePostApi } from '../services/firebaseApi';
import store from '../store/store';
import { showFlashMessage } from './flashMessage';
import {
  GET_FOOTFALL_GRID_SUPPORTING_INFO,
  UPDATE_FOOTFALL_DATA,
} from './types';


async function getEndpointData(endpoint, dataKey, payload) {
  const params = reject(isNil, payload);
  try {
    store.dispatch({
      type: UPDATE_FOOTFALL_DATA,
      payload: {
        dataKey,
        data: null,
        meta: { error: false, async: true },
      },
    });

    const response = await firebasePostApi(endpoint, params);

    store.dispatch({
      type: UPDATE_FOOTFALL_DATA,
      payload: {
        dataKey,
        data: response,
        meta: { error: false, async: false, reference: params },
      },
    });
  } catch (error) {
    store.dispatch(
      showFlashMessage({
        id: new Date().getUTCMilliseconds(),
        variant: flashMessagesVariant.error,
        message: 'Oops! something went wrong.',
        autoHide: true,
        autoHideDuration: 4000,
      }),
    );
    store.dispatch({
      type: UPDATE_FOOTFALL_DATA,
      payload: {
        dataKey,
        data: null,
        meta: { error: true, async: false },
      },
    });
  }
}

export function updateFootfallDashboard({ h3Grid, currTimePayload }) {
  try {
    const payload = {
      H3Grid: h3Grid,
      ...getTimeParams(currTimePayload),
    };

    getEndpointData(footfallEndpoints.gridData, 'gridData', payload);

    getEndpointData(
      footfallEndpoints.supportingInfo,
      'supportingInformation',
      payload,
    );
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export const getGridSupportingInfo = (params) => async (dispatch) => {
  if (!params) {
    dispatch({
      type: GET_FOOTFALL_GRID_SUPPORTING_INFO,
      payload: {
        data: null,
      },
    });
    return;
  }

  try {
    const response = await firebasePostApi(
      footfallEndpoints.supportingInfoGrid,
      reject(isNil, params),
    );

    dispatch({
      type: GET_FOOTFALL_GRID_SUPPORTING_INFO,
      payload: { data: response },
    });
  } catch (err) {
    dispatch({
      type: GET_FOOTFALL_GRID_SUPPORTING_INFO,
      payload: {
        data: null,
      },
    });
    dispatch(
      showFlashMessage({
        id: new Date().getUTCMilliseconds(),
        variant: flashMessagesVariant.error,
        message: 'Oops! something went wrong.',
        autoHide: true,
        autoHideDuration: 4000,
      }),
    );
  }
};