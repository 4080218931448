import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const LoadingIndicator = ({
  positionAbsoluteCenter,
  size,
  positionAbsolute,
}) => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: size }} spin />;

  return (
    <Spin
      indicator={loadingIcon}
      className={cx('tw-z-10', {
        'tw-absolute': positionAbsolute,
        'tw-top-1/2 tw-left-1/2': positionAbsoluteCenter,
        'tw-transform tw--translate-x-2/4 tw--translate-y-2/4':
          positionAbsoluteCenter,
      })}
    />
  );
};

LoadingIndicator.propTypes = {
  positionAbsoluteCenter: PropTypes.bool,
  size: PropTypes.number,
  positionAbsolute: PropTypes.bool,
};

LoadingIndicator.defaultProps = {
  positionAbsoluteCenter: false,
  size: 24,
  positionAbsolute: true,
};

export default LoadingIndicator;
