import { UPDATE_LAYER_MANAGEMENT } from '../actions/types';

const initialState = {
  isSidebarOpen: false,
  showLabelsOnMap: true,
  allowOverlappingLabels: false,
  mapVisualizationOpacity: 0.7,
  isMapVisualizationVisible: true,
  locationOfInterestOpacity: 1,
  isLocationOfInterestVisible: true,
  showDefaultMapboxLabels: true,
};

const layerManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LAYER_MANAGEMENT:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default layerManagementReducer;
