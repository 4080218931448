import { lazy } from 'react';

const retry = (fn, interval) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          retry(fn, interval).then(resolve, reject);
        }, interval);
      });
  });

export const lazyWithRetry = (importFunc, interval = 1000) =>
  lazy(() => retry(() => importFunc(), interval));
