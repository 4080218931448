import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { MdClose, MdSettings } from 'react-icons/md';
import onClickOutside from 'react-onclickoutside';

import { GET_USER } from '../../actions/types';
import FadeAnimation from '../../components/popups/fadeAnimation';
import { updateUser } from '../../services/firebaseHelpers';

class UserForm extends PureComponent {
  state = {
    firstName: '',
    lastName: '',
    role: '',
    showForm: false,
    fieldError: {},
    ongoing: false,
    onSuccess: false,
    onError: false,
    touched: false,
  };

  componentDidMount = () => {
    this.initValues();
  };

  initValues = () => {
    const { firstName, lastName, role } = this.props.user;
    this.setState({
      firstName,
      lastName,
      role,
    });
  };

  toggleForm = () => {
    this.setState((state) => ({ showForm: !state.showForm }));
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      fieldError: {},
      touched: true,
      onSuccess: false,
      onError: false,
    });
  };

  handleOnsubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName } = this.state;
    const fieldErrors = {};
    if (firstName.length === 0) {
      fieldErrors.firstName = 'First name is required';
    }
    if (lastName.length === 0) {
      fieldErrors.lastName = 'Last name is required';
    }
    if (Object.keys(fieldErrors).length > 0) {
      this.setState(() => ({ fieldError: fieldErrors }));
    } else {
      try {
        this.setState({ ongoing: true, onError: false });
        await updateUser({ firstName, lastName });
        this.props.dispatch({
          type: GET_USER,
          payload: { ...this.props.user, firstName, lastName },
        });
        this.setState({ ongoing: false, onSuccess: true, touched: false });
      } catch (error) {
        this.setState({ ongoing: false, onError: true });
      }
    }
  };

  handleClose = () => {
    this.setState({
      showForm: false,
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOutside() {
    this.handleClose();
  }

  render() {
    const {
      firstName,
      lastName,
      role,
      showForm,
      fieldError,
      ongoing,
      onSuccess,
      onError,
      touched,
    } = this.state;
    const showButton = !ongoing && !onSuccess && touched;
    return (
      <div className="inline-block">
        <MdSettings
          className="px-5 py-5 cursor"
          size="14"
          onClick={this.toggleForm}
        />
        <FadeAnimation id="key-user-form" show={showForm}>
          <div className="user-form bg-light br-5 dropshadow-5">
            <span className="text-xs text-dark text-bold">My profile</span>
            <MdClose
              size="12"
              style={{
                float: 'right',
                border: '1px solid #9d9e9e',
                borderRadius: '14px',
                cursor: 'pointer',
              }}
              onClick={this.handleClose}
            />
            <form
              style={{
                width: '90%',
                margin: '0 auto',
              }}
            >
              <div className="field-group jus-con-start margin-top-24 mb-1">
                <span className="field-label mw100">First name *</span>
                <div className="flex-1">
                  <input
                    className="project-input w-full"
                    name="firstName"
                    placeholder="First name"
                    maxLength="35"
                    onChange={this.handleInputChange}
                    value={firstName}
                  />
                  {fieldError.hasOwnProperty('firstName') && (
                    <span className="block text-xs negative my-4 fade">
                      {fieldError.firstName}
                    </span>
                  )}
                </div>
              </div>
              <div className="field-group jus-con-start mb-8">
                <span className="field-label mw100">Last name *</span>
                <div className="flex-1">
                  <input
                    name="lastName"
                    className="project-input w-full"
                    placeholder="Last name"
                    maxLength="35"
                    onChange={this.handleInputChange}
                    value={lastName}
                  />
                  {fieldError.hasOwnProperty('lastName') && (
                    <span className="block text-xs negative my-4 fade">
                      {fieldError.lastName}
                    </span>
                  )}
                </div>
              </div>
              <div className="field-group jus-con-start  mb-8">
                <span className="field-label mw100">Role</span>
                <span>{role}</span>
              </div>
              {ongoing && <span className="loader" />}
              {onError && (
                <span className="block text-xs text-center negative margin-top-24 fade">
                  Oops something went wrong, please try again in a moment.
                </span>
              )}
              {showButton && (
                <button
                  onClick={this.handleOnsubmit}
                  className="block survey-form-btn text-light bg-grey-1 w-216 margin-top-24 mx-auto"
                >
                  SAVE
                </button>
              )}
            </form>
          </div>
        </FadeAnimation>
      </div>
    );
  }
}

export default connect(null, null)(onClickOutside(UserForm));
