import { groupBy, map, omit, prop } from 'ramda';

import { countries } from '../../../helpers/country';
import { formatCount } from '../../../helpers/extra';
import { countryToAgeGroupMapping } from './aggregateAge';


export const MODIFIED_AGE_GROUPS_SE = {
  '0-4': ['0-4'],
  '5-9': ['5-9'],
  '10-14': ['10-14'],
  '15-19': ['15-19'],
  '20-24': ['20-24'],
  '25-34': ['25-29', '30-34'],
  '35-44': ['35-39', '40-44'],
  '45-54': ['45-49', '50-54'],
  '55-64': ['55-59', '60-64'],
  '65-74': ['65-69', '70-74'],
  '>=75': ['75-79', '>80'],
};

export const GENERATION_AGE_GROUPS_SE = {
  '5-24': ['5-9', '10-14', '15-19', '20-24'],
  '25-39': ['25-29', '30-34', '35-39'],
  '40-54': ['40-44', '45-49', '50-54'],
  '55-74': ['55-59', '60-64', '65-69', '70-74'],
};

export const MODIFIED_AGE_GROUPS = {
  '0-6': ['0-2', '3-6'],
  '7-15': ['7-12', '13-15'],
  '16-19': ['16-17', '18-19'],
  '20-24': ['20-24'],
  '25-34': ['25-29', '30-34'],
  '35-44': ['35-39', '40-44'],
  '45-54': ['45-49', '50-54'],
  '55-64': ['55-59', '60-64'],
  '65-74': ['65-69', '70-74'],
  '>=75': ['75-79', '80-84', '>85'],
};

export const GENERATION_AGE_GROUPS = {
  '7-24': ['7-12', '13-15', '16-17', '18-19', '20-24'],
  '25-39': ['25-29', '30-34', '35-39'],
  '40-54': ['40-44', '45-49', '50-54'],
  '55-74': ['55-59', '60-64', '65-69', '70-74'],
};

export const AGE_GROUPS = [
  '0-2',
  '3-6',
  '7-12',
  '13-15',
  '16-17',
  '18-19',
  '20-24',
  '25-29',
  '30-34',
  '35-39',
  '40-44',
  '45-49',
  '50-54',
  '55-59',
  '60-64',
  '65-69',
  '70-74',
  '75-79',
  '80-84',
  '>85',
  '0-4',
  '5-9',
  '10-14',
  '15-19',
];

const COMMON_INCOME_GROUPS = [
  'purchasing_power',
  'average_income',
  'labour_force',
];

const FINLAND_INCOME_GROUPS = [
  ...COMMON_INCOME_GROUPS,
  'high_income_population',
  'middle_income_population',
  'low_income_population',
];

const DENMARK_INCOME_GROUPS = [
  ...COMMON_INCOME_GROUPS,
  'income_population_below_100_000',
  'income_population_between_100_000_199_999',
  'income_population_between_200_000_299_999',
  'income_population_between_300_000_399_999',
  'income_population_between_400_000_499_999',
  'income_population_more_500_000',
];

export const countryToPopupIncomeGroups = (country) => {
  if (country === countries.DENMARK) {
    return DENMARK_INCOME_GROUPS;
  } else if (country === countries.FINLAND) {
    return FINLAND_INCOME_GROUPS;
  }
  return {};
};

export const aggregatePopupIncome = (allIncomeGroups, country) => {
  const incomeGroups = countryToPopupIncomeGroups(country);
  return Object.assign(
    {},
    ...incomeGroups.map((x) => ({ [x]: allIncomeGroups[x] || null })),
  );
};

export const EDU_GROUPS = [
  'university_degree_total',
  'vocational_diploma',
  'other',
];

export const ADVANCED_HOUSEHOLD_GROUPS = {
  withChildren: 'With children',
  withoutChildren: 'Without children',
  youngSinglesAndCouples: 'Young singles & couples < 35 years',
  pensioners: 'Pensioners',
};

export const ADVANCED_HOUSEHOLDS_WITH_CHILDREN = {
  '0-2': 'With children of age 0-2',
  '<7': 'With children of age < 7',
  '7-12': 'With children of age 7-12',
  '13-17': 'With children of age 13-17',
};

export const ADVANCED_HOUSEHOLDS_INCOME_DISTRIBUTION = {
  highIncomeHouseholds: 'high',
  middleIncomeHouseholds: 'middle',
  lowIncomeHouseholds: 'low',
  incomeBelow_200_000: '< 200k DKK',
  incomeBetween_200_000_399_999: '200k-400k DKK',
  incomeBetween_400_000_599_999: '400k-600k DKK',
  incomeBetween_600_000_799_999: '600k-800k DKK',
  incomeMore_800_000: '> 800k DKK',
};

export const filterFields = (indicator, country) => {
  switch (indicator) {
    case 'Population by age and gender':
      return Object.keys(countryToAgeGroupMapping(country));

    case 'Population by gender':
      return ['males', 'females'];

    case 'Population by age gender':
      return AGE_GROUPS;

    case 'Population by education':
      return EDU_GROUPS;

    case 'Population by income':
      return [
        'high_income_population',
        'middle_income_population',
        'low_income_population',
      ];

    case 'Unemployment':
      return ['employed', 'unemployed', 'pensioners', 'students', 'others'];

    default:
      throw new Error('Indicator type not found for', indicator);
  }
};

export const sortByOrder = (data, order, key) => {
  if (data.length === 0 || order.length === 0) {
    return data;
  }
  const groupByKey = groupBy(prop(key), data);
  const sort = map((k) => groupByKey[k][0]);
  return sort(order.filter((k) => data.find((x) => x[key] === k)));
};

export const fieldOrder = [
  'income_trend',
  'purchasing_power',
  'average_income',
  'low_income_population',
  'middle_income_population',
  'high_income_population',
  'income_population_below_100_000',
  'income_population_between_100_000_199_999',
  'income_population_between_200_000_299_999',
  'income_population_between_300_000_399_999',
  'income_population_between_400_000_499_999',
  'income_population_more_500_000',
  '0-4',
  '5-9',
  '10-14',
  '15-19',
  '0-6',
  '7-15',
  '16-19',
  '20-24',
  '25-34',
  '35-44',
  '45-54',
  '55-64',
  '65-74',
  '>=75',
  'male',
  'female',
  'labour_force',
  'unemployment_rate',
  'unemployment_trend',
  'University',
  'Vocational',
  'Other_education',
];

export const addPercent = (x) => `${formatCount(x)}%`;
export const makeEuro = (x) => `${formatCount(x)} €`;
export const makeDanishKrona = (x) => `${formatCount(x)} DKK`;
export const addSqrMeter = (x) => `${x} \u33A1`;

const COMMON_UNIT_MAP = {
  '0-6': formatCount,
  '7-15': formatCount,
  '16-19': formatCount,
  '20-24': formatCount,
  '25-34': formatCount,
  '35-44': formatCount,
  '45-54': formatCount,
  '55-64': formatCount,
  '65-74': formatCount,
  '>75': formatCount,
  labour_force: formatCount,
  occupancy_rate: (x) => `${x} m<sup>2</sup>`,
  total_households: formatCount,
};

const FINLAND_UNIT_MAP = {
  ...COMMON_UNIT_MAP,
  high_income_population: addPercent,
  middle_income_population: addPercent,
  low_income_population: addPercent,
  purchasing_power: makeEuro,
  average_income: makeEuro,
};

const DENMARK_UNIT_MAP = {
  ...COMMON_UNIT_MAP,
  income_population_more_500_000: addPercent,
  income_population_between_400_000_499_999: addPercent,
  income_population_between_300_000_399_999: addPercent,
  income_population_between_200_000_299_999: addPercent,
  income_population_between_100_000_199_999: addPercent,
  income_population_below_100_000: addPercent,
  purchasing_power: makeDanishKrona,
  average_income: makeDanishKrona,
};

export const getUnitMap = (country) => {
  if (country === countries.DENMARK) {
    return DENMARK_UNIT_MAP;
  } else if (country === countries.FINLAND) {
    return FINLAND_UNIT_MAP;
  }
  return {};
};

export const unitMapHouseholds = {
  household_growth_rate: addPercent,
  average_size_of_households: '',
  average_floor_area_per_person: addSqrMeter,
  total_households: formatCount,
  households_with_children: addPercent,
  households_without_children: addPercent,
  pensioner_households: addPercent,
  young_singles_and_couples: addPercent,
};

export const removeDataKeys = (opt, data) => {
  switch (opt) {
    case 'Unemployment rate':
      return omit(
        ['year', 'radius', 'inhabitants_total', 'unemployment_trend'],
        data,
      );
    case 'Unemployment trend':
      return omit(
        ['year', 'radius', 'inhabitants_total', 'labour_force'],
        data,
      );
    case 'Average income':
      return omit(
        ['year', 'radius', 'inhabitants_total', 'income_trend'],
        data,
      );
    case 'Income trend':
      return omit(['year', 'radius', 'inhabitants_total'], data);
    default:
      return omit(['year', 'radius'], data);
  }
};