export const footfallEndpoints = {
  supportingInfo: '/indexes/footfall/supporting-information',
  supportingInfoGrid: '/indexes/footfall/supporting-information/grid',
  gridData: '/indexes/footfall/grid',
};

export function getTimeParams(timeData = {}) {
  switch (timeData.activeFormat) {
    case 'year':
      return {};
    case 'month':
      return { Year: timeData.year };
    case 'day':
      return { Year: timeData.year, Month: timeData.month };
    default:
      return {};
  }
}
