import React from 'react';

export const STATUS = {
  online: 'online',
  offline: 'offline',
};

function useInternetStatus() {
  const [status, setStatus] = React.useState(STATUS.online);

  React.useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);

  function handleConnectionChange() {
    const connection = navigator.onLine ? STATUS.online : STATUS.offline;
    setStatus(connection);
  }

  return status;
}

export default useInternetStatus;
