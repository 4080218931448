import dayjs from 'dayjs';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(isoWeeksInYear);
dayjs.extend(isLeapYear);
dayjs.extend(isoWeek);

export default (history) => {
  const maxWeek = dayjs().isoWeeksInYear();
  const currentWeek = dayjs().isoWeek();
  const currentYear = dayjs().year();
  let data = {};
  let allWeeks = {};
  for (let week = 1; week < maxWeek + 1; week += 1) {
    allWeeks = { ...allWeeks, [week]: 0 };
  }
  if (!history) {
    data = { ...allWeeks, year: currentYear, [currentWeek]: 1 };
  }
  if (history) {
    // handle week change
    if (history.year === currentYear) {
      data = {
        ...history,
        [currentWeek]: history[currentWeek] + 1,
      };
      // handle year change
    } else {
      data = { ...allWeeks, year: currentYear, [currentWeek]: 1 };
    }
  }
  return data;
};
