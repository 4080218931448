import { Input } from 'antd';
import cx from 'classnames';
import React from 'react';

import styles from './_inputField.module.scss';

const InputField = ({
  field,
  fieldType = 'text',
  disabled = false,
  autoComplete = '',
  form: { touched, errors },
  ...props
}) => (
  <div className={styles.formFieldWrapper}>
    <Input
      type={fieldType}
      {...field}
      {...props}
      autoComplete={autoComplete}
      className={cx({
        [styles.disabled]: disabled,
      })}
    />

    <span className="fade text-error text-s">
      {!disabled && touched[field.name] && errors[field.name] && (
        <span>{errors[field.name]}</span>
      )}
    </span>
  </div>
);

export default InputField;
