import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { MdCheckCircle } from 'react-icons/md';
import { object, ref, string } from 'yup';

import { assoc, reduce } from 'ramda';
import { useLocation, useNavigate } from 'react-router-dom';
import passwordRenewedIconGray from '../images/icons/password-renewed-gray-icon.svg';
import brandLogo from '../images/logo_black_new.png';

const passwordSchema = object().shape({
  newPassword: string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, {
      message: 'New password did not match the criteria',
    })
    .required('New password is required')
    .label('newPasswordError'),
  confirmPassword: string()
    .oneOf([ref('newPassword'), null], 'Passwords did not match!')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, {
      message: 'Confirm password did not match the criteria',
    })
    .required('Confirm password is required')
    .label('confirmPasswordError'),
});

const PasswordReset = () => {
  const [state, setState] = useState({
    authCode: '',
    newPassword: '',
    newPasswordError: '',
    confirmPassword: '',
    confirmPasswordError: '',
    ongoing: false,
    success: false,
    onError: false,
  });

  const {
    ongoing,
    success,
    onError,
    newPasswordError,
    confirmPasswordError,
    authCode,
    newPassword,
    confirmPassword,
  } = state;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const parsed = qs.parse(location.search);
    const code = parsed.oobCode;

    if (!code) navigate('/');

    firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(() => {
        setState((prevState) => ({ ...prevState, authCode: code }));
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  const handleInputChange = ({ target: { name, value } }) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      newPasswordError: '',
      confirmPasswordError: '',
      ongoing: false,
      success: false,
      onError: false,
    }));
  };

  const handleButtonOnsubmit = async () => {
    try {
      passwordSchema.validateSync(
        { newPassword, confirmPassword },
        {
          strict: true,
          abortEarly: false,
          stripUnknown: true,
        },
      );
    } catch (error) {
      const errors = reduce(
        (acc, err) => assoc(err.params.label, err.message, acc),
        {},
        error?.inner || {},
      );

      setState((prevState) => ({ ...prevState, ...errors }));
      return;
    }

    setState((prevState) => ({ ...prevState, ongoing: true }));

    firebase
      .auth()
      .confirmPasswordReset(authCode, newPassword)
      .then(() =>
        setState((prevState) => ({
          ...prevState,
          newPassword: '',
          newPasswordError: '',
          confirmPassword: '',
          confirmPasswordError: '',
          authCode: '',
          ongoing: false,
          success: true,
          onError: false,
        })),
      )
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          ongoing: false,
          onError: true,
          success: false,
        }));
      });
  };

  const handleSuccess = () => {
    navigate('/');
  };

  return (
    <div className="login__bgwrapper fade">
      <Helmet>
        <title>Password reset - CHAOSarchitects</title>
      </Helmet>
      <div className="w-full">
        <img
          className="brand-image text-center mb-16"
          src={brandLogo}
          alt="chaosarchitects_brand_logo_white"
        />
        {success ? (
          <div className="loginform__wrapper dropshadow-5 br-5 py-2 fade">
            <h2 className="form-title text-center">New password set!</h2>
            <img className="notifi-icon" src={passwordRenewedIconGray} alt="" />
            <span className="notifi-text">
              You can now sign in with your new password
            </span>
            <button className="btn__default br-5" onClick={handleSuccess}>
              GOT IT!
            </button>
          </div>
        ) : (
          <div className="loginform__wrapper dropshadow-5 br-5 py-2">
            <div className="fade">
              <h2 className="form-title text-center">Reset password</h2>
              <div className="field-group">
                <input
                  className="password-input"
                  name="newPassword"
                  type="password"
                  onChange={handleInputChange}
                  placeholder="New password"
                />
              </div>
              {newPasswordError && (
                <div className="fade text-error text-s mb-1">
                  {newPasswordError}
                </div>
              )}
              <div className="field-group">
                <input
                  className="password-input"
                  name="confirmPassword"
                  type="password"
                  onChange={handleInputChange}
                  placeholder="Confirm password"
                />
              </div>
              {confirmPasswordError && (
                <div className="fade text-error text-s">
                  {confirmPasswordError}
                </div>
              )}
              <ul className="password-info-list">
                <li className="password-info-item">
                  <MdCheckCircle /> &nbsp;At least 8 characters long
                </li>
                <li className="password-info-item">
                  <MdCheckCircle /> &nbsp;Contains uppercase letters
                </li>
                <li className="password-info-item">
                  <MdCheckCircle /> &nbsp;Contains lowercase letters
                </li>
                <li className="password-info-item">
                  <MdCheckCircle /> &nbsp;Contains numbers
                </li>
              </ul>
              {onError && (
                <span className="text-center text-s text-error mb-1 fade">
                  Something went wrong! Please try again.
                </span>
              )}
              <br />
              {ongoing ? (
                <span className="loading" />
              ) : (
                <button
                  className="btn__default br-5"
                  onClick={handleButtonOnsubmit}
                >
                  RESET PASSWORD
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PasswordReset;
