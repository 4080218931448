import { UPDATE_LOCATION_OF_INTEREST } from '../actions/types';

export const LOCATION_OF_INTEREST_VISIBILITY_FILTER = {
  all: 'all',
  private: 'private',
  shared: 'shared',
};

const initialState = {
  isSidebarOpen: false,
  isPointsVisible: false,
  isFilterViewOpen: false,
  filters: {},
  searchString: '',
  visibilityFilter: LOCATION_OF_INTEREST_VISIBILITY_FILTER.all,
};

const locationOfInterestReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOCATION_OF_INTEREST:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default locationOfInterestReducer;
