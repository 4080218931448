import turfArea from '@turf/area';
import turfBbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import getCirclePolygon from '@turf/circle';
import { cellToParent, polygonToCells } from 'h3-js';
import { last, uniq } from 'ramda';

import { setLayerVisibility } from '../dashboard/common/helpers';
import { MAP_STYLES_MAP } from '../dashboard/layerManagement/helpers';

export const MIN_AREA = 300; // square km

export const DEFAULT_COORDINATE = [24.9415, 60.1705]; // Helsinki

export const COUNTRY_CAPITAL_COORDINATE = {
  fi: DEFAULT_COORDINATE,
  dk: [12.56, 55.67], // Copenhagen
  se: [18.06861, 59.32944], // Stockholm
};

const MAPBOX_DEFAULT_HIDDEN_LAYERS = ['poi-label'];

const MAPBOX_DEFAULT_LABEL_LAYERS = [
  'road-label',
  'waterway-label',
  'water-line-label',
  'water-point-label',
  'airport-label',
  'settlement-subdivision-label',
  'settlement-label',
  'state-label',
  'country-label',
  'road-intersection',
  'road-number-shield',
  'road-exit-shield',
  'ferry-aerialway-label',
  'transit-label',
  'settlement-minor-label',
  'settlement-major-label',
  'continent-label',
];

export const changeMapStyle = (mapStyleId, map) =>
  new Promise((resolve) => {
    if (map && MAP_STYLES_MAP[mapStyleId]) {
      const currentStyle = map.getStyle();
      const newStyleUrl = MAP_STYLES_MAP[mapStyleId].styleUrl;

      // style.load doesn't get called if we set the same style
      // which causes the map layers to disappear
      const currentStyleId = last(currentStyle.sprite.split('/'));
      const newStyleId = last(newStyleUrl.split('/'));

      if (currentStyleId !== newStyleId) {
        map.setStyle(newStyleUrl);

        map.once('style.load', () => {
          const newStyle = map.getStyle();

          // only keep custom layers
          const appLayers = currentStyle.layers.filter(
            (el) =>
              el.source && el.id !== 'satellite' && el.source !== 'composite',
          );

          newStyle.sources = {
            ...currentStyle.sources,
            ...newStyle.sources,
          };

          newStyle.layers = [...newStyle.layers, ...appLayers];

          map.setStyle(newStyle);
          resolve();
        });
      } else {
        resolve();
      }
    } else {
      resolve();
    }
  });

export const getCenterBbox = (bbox) => {
  if (bbox?.length !== 2) return null;
  const lngCenterBbox = (bbox[0][0] + bbox[1][0]) / 2;
  const latCenterBbox = (bbox[0][1] + bbox[1][1]) / 2;
  return [lngCenterBbox, latCenterBbox];
};

export const getDefaultCircleBbox = (center) => {
  const radius = Math.floor(Math.sqrt(MIN_AREA)) / 2;
  const newPolygon = getCirclePolygon(center, radius, 48, 'kilometers');
  return turfBbox(newPolygon);
};

export function getValidBbox(bbox, center) {
  const area = turfArea(bboxPolygon(bbox));
  const ValidAreaInSquareMetre = MIN_AREA * 1000000;

  if (ValidAreaInSquareMetre > area) {
    return bbox;
  }
  return getDefaultCircleBbox(center);
}

export const getMapBoundsFromBbox = (boundingBox) => {
  const west = boundingBox[0];
  const south = boundingBox[1];
  const east = boundingBox[2];
  const north = boundingBox[3];

  const bounds = [
    [west, south],
    [east, north],
  ];

  return bounds;
};

export const getH3Cells = (
  polygon,
  targetResolution,
  searchResolution,
  isGeoJson,
) => {
  try {
    const polygonCoordinates = polygon?.geometry?.coordinates[0];
    if (!polygonCoordinates || polygonCoordinates.length < 3) return [];

    const validSearchResolution = Math.max(searchResolution, targetResolution);

    const getParentCells = (cells, resolution) => {
      if (resolution > targetResolution) {
        const parentCells = cells.map((cell) =>
          cellToParent(cell, targetResolution),
        );
        return getParentCells(parentCells, resolution - 1);
      }
      return cells;
    };

    const getCells = (resolution) => {
      const cells = polygonToCells(polygonCoordinates, resolution, isGeoJson);
      if (cells.length <= 0 && resolution < searchResolution) {
        return getCells(resolution + 1);
      }
      return getParentCells(cells, resolution);
    };

    const validCells = uniq(getCells(validSearchResolution));

    return validCells;
  } catch (e) {
    return [];
  }
};

export const setMapboxDefaultLayerVisibility = (
  map,
  showDefaultMapboxLabels,
) => {
  MAPBOX_DEFAULT_HIDDEN_LAYERS.map((layerName) =>
    setLayerVisibility(map, layerName, false),
  );
  MAPBOX_DEFAULT_LABEL_LAYERS.map((layerName) =>
    setLayerVisibility(map, layerName, showDefaultMapboxLabels),
  );
};
