import React from 'react';
import { Helmet } from 'react-helmet';

import brandLogo from '../images/logo_black_new.png';
import LoginForm from './login/LoginForm';

const BackgroundWrapper = ({ htmlTitle, children }) => (
  <div className="login__bgwrapper fade">
    <Helmet>
      <title>{htmlTitle}</title>
    </Helmet>
    <div className="w-full my-2">
      <img
        className="brand-image text-center mb-16"
        src={brandLogo}
        alt="chaosarchitects_brand_logo_white"
      />
      {children}
    </div>
  </div>
);

function LogIn() {
  return (
    <BackgroundWrapper htmlTitle="CHAOS dashboard">
      <LoginForm />
    </BackgroundWrapper>
  );
}

export default LogIn;
