import React from 'react';

import PropTypes from 'prop-types';

import Portal from './Portal';

const LoaderText = ({ displayText, bgWhite, header, loaderStyle }) => (
  <Portal>
    <div className={`spinner-container ${bgWhite && 'bg-white'}`}>
      <div className="spinner-content dropshadow-5 flex-column">
        {header}
        <div className="align-items-center flex" style={loaderStyle}>
          <span className="spinner" />
          <span className="text-r px-10">{displayText}</span>
        </div>
      </div>
    </div>
  </Portal>
);

LoaderText.propTypes = {
  displayText: PropTypes.string.isRequired,
  bgWhite: PropTypes.bool,
  header: PropTypes.node,
  loaderStyle: PropTypes.objectOf(PropTypes.any),
};

LoaderText.defaultProps = {
  bgWhite: false,
  header: undefined,
  loaderStyle: {},
};

export default LoaderText;
