import {
  NO_DATA,
  ON_SCROLL,
  SET_IS_DISTANCE_MEASUREMENT_ENABLED,
  SET_IS_KNOWLEDGE_BASE_SIDEBAR_OPEN,
  SET_IS_MAP_STYLE_VIEW_ENABLED,
  UI_ERROR,
  UI_LOADING,
} from '../actions/types';

const DEFAULT_STATE = {
  isLoading: false,
  error: false,
  noData: false,
  scrollPosition: 0,
  isDistanceMeasurementEnabled: false,
  isKnowledgeBaseSideBarOpen: false,
};
export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case UI_LOADING:
      return { ...state, isLoading: action.payload };
    case UI_ERROR:
      return { ...state, error: action.payload };
    case NO_DATA:
      return { ...state, noData: action.payload };
    case ON_SCROLL:
      return { ...state, scrollPosition: action.payload };
    case SET_IS_DISTANCE_MEASUREMENT_ENABLED:
      return { ...state, isDistanceMeasurementEnabled: action.payload };
    case SET_IS_KNOWLEDGE_BASE_SIDEBAR_OPEN:
      return { ...state, isKnowledgeBaseSideBarOpen: action.payload };
    default:
      return state;
  }
}
