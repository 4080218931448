export const countries = {
  FINLAND: 'fi',
  DENMARK: 'dk',
  SWEDEN: 'se',
  MEXICO: 'mx',
};

export const countryCodeToName = {
  [countries.FINLAND]: 'Finland',
  [countries.DENMARK]: 'Denmark',
  [countries.SWEDEN]: 'Sweden',
  [countries.MEXICO]: 'Mexico',
};
