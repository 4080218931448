import { indicators } from '../pages/forecast/maps/constants';
import {
  SET_COMMON_SLIDER_DATA,
  SET_COMMON_SLIDER_RANGE,
  SET_COMMON_SLIDER_YEAR,
  SET_SLIDER_INDICATOR_DATA,
} from './types';
import { updateIndicatorData } from './updateIndicatorData';

export const setCommonSliderYear = (year) => ({
  type: SET_COMMON_SLIDER_YEAR,
  payload: year,
});

export const setCommonSliderRange = (range) => ({
  type: SET_COMMON_SLIDER_RANGE,
  payload: range,
});

export const setCommonSliderData = (data) => ({
  type: SET_COMMON_SLIDER_DATA,
  payload: data,
});

export const setIndicatorSliderData = (payload) => ({
  type: SET_SLIDER_INDICATOR_DATA,
  payload,
});

export const updateIndicatorSliderData =
  ({ indicatorName, activeIndicatorTimeData, country, h3Grid }) =>
  () => {
    if (indicatorName === indicators.Footfall) {
      updateIndicatorData({
        indicatorName,
        currTimePayload: activeIndicatorTimeData,
        Country: country,
        h3Grid,
      });
    }
  };
