import { householdTypesEndpoints } from '../api/householdTypesIndicator';
import { indicators } from '../pages/forecast/maps/constants';
import { firebaseGetApi, firebasePostApi } from '../services/firebaseApi';
import store from '../store/store';
import { forecastingResponseHasMinAndMaxYear } from './helpers';
import { setCommonSliderRange } from './timeSlider';
import {
  CLEAR_FORECAST_DATA,
  GET_FORECAST_DATA,
  GET_FORECAST_DATA_ASYNC,
  GET_FORECAST_DATA_ERROR,
  REMOVE_DASHBOARD_DATA,
  SET_DASHBOARD_MAP_BOUND,
} from './types';

const AGE_ENDPOINT = 'population-by-age';
const INCOME_ENDPOINT = 'population-by-income';
const MAIN_ACTIVITY_ENDPOINT = 'population-by-main-activity';
const EDUCATION_ENDPOINT = 'population-by-education';
const SERVICE_MIX_ENDPOINT = 'service-mix';

export const getForecastingData =
  (endpoint, tableName, body) => async (dispatch) => {
    const ON_DEMAND_ENDPOINTS = [
      'footfall',
      'property-market',
      'office-market',
      AGE_ENDPOINT,
      INCOME_ENDPOINT,
      MAIN_ACTIVITY_ENDPOINT,
      EDUCATION_ENDPOINT,
      SERVICE_MIX_ENDPOINT,
      'area-development',
      'population-structure',
      'residential-demand-supply',
    ];
    if (ON_DEMAND_ENDPOINTS.includes(endpoint)) {
      // since these endpoints are fetched on demand
      return;
    }
    try {
      dispatch({
        type: GET_FORECAST_DATA_ERROR,
        payload: { key: tableName, error: false },
      });
      dispatch({
        type: GET_FORECAST_DATA_ASYNC,
        payload: { async: true, key: tableName },
      });

      let res;

      if (endpoint === 'advanced-household-types') {
        const supportingInfoResponse = await firebaseGetApi(
          householdTypesEndpoints.supportingInformation,
          {
            Country: body.Country,
            Areas: JSON.stringify(body.Areas),
          },
        );

        res = {
          data: {
            supportingInformation: supportingInfoResponse,
          },
        };
      } else {
        res = await firebasePostApi(`/${endpoint}`, body);
      }

      if (forecastingResponseHasMinAndMaxYear(res)) {
        dispatch(
          setCommonSliderRange({
            minYear: res.data.data.MinYear || res.data.data.minYear,
            maxYear: res.data.data.MaxYear || res.data.data.maxYear,
          }),
        );
      }

      dispatch({
        type: GET_FORECAST_DATA,
        payload: { key: tableName, res: res.data },
      });
      dispatch({
        type: GET_FORECAST_DATA_ASYNC,
        payload: { async: false, key: tableName },
      });
    } catch (err) {
      dispatch({
        type: GET_FORECAST_DATA_ERROR,
        payload: { key: tableName, error: true },
      });
      dispatch({
        type: GET_FORECAST_DATA_ASYNC,
        payload: { async: false, key: tableName },
      });
    }
  };

export const clearForecastingData = () => ({
  type: CLEAR_FORECAST_DATA,
});

export const fetchDemographicIndex = (indexName) => async (dispatch) => {
  let endpoint = null;

  if (indexName === indicators['Population by age and gender']) {
    endpoint = AGE_ENDPOINT;
  }

  if (indexName === indicators['Population by income']) {
    endpoint = INCOME_ENDPOINT;
  }

  if (indexName === indicators.Unemployment) {
    endpoint = MAIN_ACTIVITY_ENDPOINT;
  }

  if (indexName === indicators['Population by education']) {
    endpoint = EDUCATION_ENDPOINT;
  }

  let body = store.getState();
  body = {
    Country: body.forecastStore.country,
    Areas: body.forecastStore.postalcodes,
  };

  try {
    dispatch({
      type: GET_FORECAST_DATA_ERROR,
      payload: { key: indexName, error: false },
    });
    dispatch({
      type: GET_FORECAST_DATA_ASYNC,
      payload: { async: true, key: indexName },
    });

    const response = await firebasePostApi(`/${endpoint}`, body);

    if (forecastingResponseHasMinAndMaxYear(response)) {
      dispatch(
        setCommonSliderRange({
          minYear: response.data.data.MinYear || response.data.data.minYear,
          maxYear: response.data.data.MaxYear || response.data.data.maxYear,
        }),
      );
    }

    dispatch({
      type: GET_FORECAST_DATA,
      payload: { key: indexName, res: response.data },
    });
    dispatch({
      type: GET_FORECAST_DATA_ASYNC,
      payload: { async: false, key: indexName },
    });
  } catch (err) {
    dispatch({
      type: GET_FORECAST_DATA_ERROR,
      payload: { key: indexName, error: true },
    });
    dispatch({
      type: GET_FORECAST_DATA_ASYNC,
      payload: { async: false, key: indexName },
    });
  }
};

export const setDashboardMapBound = (dashboard, bound) => ({
  type: SET_DASHBOARD_MAP_BOUND,
  payload: { key: dashboard, data: bound },
});

export const removeDashboardData = (dashboard) => ({
  type: REMOVE_DASHBOARD_DATA,
  payload: dashboard,
});