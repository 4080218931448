import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Portal from '../../../components/popups/Portal';
import styles from './_HsForm.module.scss';
import Button, { ButtonType } from '../../../components/button';
import { MdClose, MdSend } from 'react-icons/md';
import { Field, Form, Formik } from 'formik';
import { hubSpotContactSalesFormSchema } from '../../../components/form/validationSchema';
import InputField from '../../../components/form/InputField';
import { formatToHubSpot, contactSalesForm } from '../../../services/hubspot';
import FadeAnimation from '../../../components/popups/fadeAnimation';

function SuccessModal({ handleClose, wrapperStyle = {} }) {
  return (
    <div className={styles.root} style={wrapperStyle}>
      <div className="p-32 dropshadow-5 br-5 bg-light">
        <h2 className="form-title text-center">Thank you!</h2>
        <div className="text-center">
          <MdSend size="72" style={{ transform: 'rotate(-45deg)' }} />
        </div>
        <span className="notifi-text">
          We will get back to you as soon as possible.
        </span>
        <button className="btn__default br-5" onClick={() => handleClose()}>
          Ok
        </button>
      </div>
    </div>
  );
}

function HubSpotContactSalesForm({
  featureName,
  showHubSpotForm,
  setShowHubSpotForm,
  userInfo,
  wrapperStyle = {},
  disableClose = false,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [onSuccess, setOnSuccess] = React.useState(false);

  React.useEffect(() => {
    if (showHubSpotForm === false) {
      setError(false);
    }
  }, [showHubSpotForm]);

  const initialValues = {
    company: '',
    phone: '',
    salesTrial: '',
    ...userInfo,
  };

  async function handleSubmit(values, formHelpers) {
    try {
      setError(false);
      setOnSuccess(false);
      setIsLoading(true);
      const formValues = {
        email: values.email.trim().toLowerCase(),
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        company: values.company,
        phone: values.phone.trim(),
        sales_trial: values.salesTrial.trim(),
      };
      await contactSalesForm({ fields: formatToHubSpot(formValues) });
      formHelpers.resetForm({ ...initialValues });
      setShowHubSpotForm(false);
      setOnSuccess(true);
    } catch (error) {
      setOnSuccess(false);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Portal>
      <FadeAnimation id="hs-success-modal" show={onSuccess}>
        <SuccessModal
          handleClose={() => {
            setOnSuccess(false);
          }}
        />
      </FadeAnimation>
      <FadeAnimation id="hs-form" show={showHubSpotForm}>
        <div className={cx(styles.root, 'fade')} style={wrapperStyle}>
          <div className={styles.bodyWrap}>
            <div className={cx('text-right', styles.actionBar)}>
              {!disableClose && (
                <button
                  className="btn-text cursor"
                  onClick={() => setShowHubSpotForm(false)}
                >
                  <MdClose size="16" />
                </button>
              )}
            </div>
            <div className={styles.body}>
              <h2 className="form-title text-center">
                You discovered a Premium Feature!
              </h2>
              <div className="px-16">
                <p className="text-s">
                  <span className="text-bold">{featureName}</span> is a part of
                  our paid subscription, but you can take most out of it during
                  a free 2-week trial.Request a trial if you want to explore the
                  feature or contact our Sales directly for upgrading your plan.
                </p>
                <Formik
                  initialValues={{ ...initialValues }}
                  validationSchema={hubSpotContactSalesFormSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue }) => (
                    <Form>
                      <Field
                        component={InputField}
                        name="email"
                        placeholder="* Email"
                        autoComplete="email"
                      />
                      <Field
                        component={InputField}
                        name="firstName"
                        placeholder="* First name"
                        autoComplete="given-name"
                        onChange={({ target }) => {
                          setError(false);
                          setFieldValue(target.name, target.value);
                        }}
                      />
                      <Field
                        component={InputField}
                        name="lastName"
                        placeholder="* Last name"
                        autoComplete="family-name"
                        onChange={({ target }) => {
                          setError(false);
                          setFieldValue(target.name, target.value);
                        }}
                      />
                      <Field
                        component={InputField}
                        name="company"
                        placeholder="* Company name"
                        autoComplete="organization"
                        onChange={({ target }) => {
                          setError(false);
                          setFieldValue(target.name, target.value);
                        }}
                      />
                      <Field
                        component={InputField}
                        fieldType="tel"
                        name="phone"
                        placeholder="Phone number"
                        autoComplete="tel"
                        onChange={({ target }) => {
                          setError(false);
                          setFieldValue(target.name, target.value);
                        }}
                      />
                      {error && (
                        <div className="text-center text-s text-error my-16 fade">
                          Something went wrong! Please try again.
                        </div>
                      )}
                      {isLoading && <span className="loading"></span>}
                      <Button
                        disabled={isLoading}
                        type={ButtonType.PRIMARY}
                        onClick={() => setFieldValue('salesTrial', 'trial')}
                        style={{ marginBottom: 8 }}
                        isSubmit={true}
                      >
                        Request a free trial
                      </Button>
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="bttn lg outline w-full"
                        onClick={() => setFieldValue('salesTrial', 'sales')}
                      >
                        Contact sales directly
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </FadeAnimation>
    </Portal>
  );
}

function mapStateToProps(state) {
  const { firstName, lastName, email, companyName } = state.user.info;
  return {
    userInfo: { firstName, lastName, email, company: companyName || '' },
  };
}

export default connect(mapStateToProps, null)(HubSpotContactSalesForm);
