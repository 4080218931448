import bboxPolygon from '@turf/bbox-polygon';
import turfBuffer from '@turf/buffer';
import {
  difference,
  find,
  flatten,
  indexBy,
  isEmpty,
  isNil,
  or,
  prop,
  propEq,
  reject,
  sortBy,
  toLower,
} from 'ramda';
import { createRef } from 'react';
import { v4 as uuid } from 'uuid';

import { COUNTRY_CAPITAL_COORDINATE } from '../helpers/mapUtils';

const RECENTLY_USED_INSIGHTS = 'recently_used_reporting_insights';

export const MAP_FLY_ANIMATION_DURATION = 1000;

export const REPORT_STATUS_FILTER_DATASET = {
  allReports: 'allReports',
  published: 'published',
  unpublished: 'unpublished',
};

export const REPORT_ORIENTATION = {
  undefined,
  landscape: 'landscape',
  portrait: 'portrait',
};

export const COLORS = {
  background: '#F5F5F5',
};

export const FONT_SIZE = {
  toolbarIconSize: 16,
};

export const REPORT_MENU_TABS = {
  setup: 'reportMenuTabSetup',
  insights: 'reportMenuTabInsights',
  tutorial: 'reportMenuTabTutorial',
  settings: 'reportMenuTabSettings',
};

export const filterReports = (reports, filters) =>
  reports
    ?.filter((report) => {
      if (filters?.status === REPORT_STATUS_FILTER_DATASET.published)
        return report.isPublished === true;
      if (filters?.status === REPORT_STATUS_FILTER_DATASET.unpublished)
        return report.isPublished === false;

      return true;
    })
    .filter((report) =>
      filters?.creator
        ? report?.creator
            ?.toLowerCase()
            .includes(filters?.creator?.toLowerCase())
        : true,
    )
    .filter((report) =>
      filters?.project
        ? report?.project
            ?.toLowerCase()
            .includes(filters?.project?.toLowerCase())
        : true,
    );

export const isNilOrEmpty = (value) => or(isNil(value), isEmpty(value));

export const areMapBoundsEqual = (mapBoundA, mapBoundB) => {
  if (!mapBoundA || !mapBoundB) {
    return false;
  }

  return (
    mapBoundA[1][0].toFixed(6) === mapBoundB[1][0].toFixed(6) &&
    mapBoundA[0][1].toFixed(6) === mapBoundB[0][1].toFixed(6) &&
    mapBoundA[0][0].toFixed(6) === mapBoundB[0][0].toFixed(6) &&
    mapBoundA[1][1].toFixed(6) === mapBoundB[1][1].toFixed(6)
  );
};

export const getPolygonFromBbox = (boundingBox) => {
  if (isNil(boundingBox) || isEmpty(boundingBox)) {
    return null;
  }
  const polygon = bboxPolygon([
    boundingBox[1][0],
    boundingBox[0][1],
    boundingBox[0][0],
    boundingBox[1][1],
  ]);

  return polygon;
};

export const getNewEmptyPageElement = () => ({
  ref: createRef(),
  layouts: [],
  id: uuid(),
});

export const filterInsights = (insightGroups, searchTerm) => {
  if (isNilOrEmpty(searchTerm)) return insightGroups;

  const filteredGroups = insightGroups?.map((insightGroup) => {
    const matchedInsights = insightGroup?.insights?.filter(({ title }) =>
      toLower(title)?.includes(toLower(searchTerm)),
    );

    return {
      ...insightGroup,
      insights: matchedInsights,
    };
  });

  return reject((group) => isEmpty(group?.insights), filteredGroups);
};

export const filterRecentlyUsedInsights = (
  insightGroups,
  recentlyUsedComponentIds,
) => {
  const filteredAvailableInsights = insightGroups.filter(
    ({ isDisabled }) => !isDisabled,
  );
  const flattenedInsights = flatten(
    filteredAvailableInsights.map(({ insights }) => insights),
  );
  const insightsMap = indexBy(prop('id'), flattenedInsights);

  const onlyAvailableInsights = recentlyUsedComponentIds.map(
    (componentId) => insightsMap[componentId],
  );

  return reject(isNil, onlyAvailableInsights);
};

export const getRecentlyUsedInsightsFromStorage = () => {
  const insights = localStorage.getItem(RECENTLY_USED_INSIGHTS);

  if (!insights) return [];

  return JSON.parse(insights);
};

export const saveRecenltyUsedInsightsInStorage = (insigtsList) => {
  localStorage.setItem(RECENTLY_USED_INSIGHTS, JSON.stringify(insigtsList));
};

export const COLOR_PICKER_COLORS = [
  '#1F1F1F',
  '#722ED1',
  '#2F54EB',
  '#52C41A',
  '#FADB14',
  '#FA8C16',
  '#F5222D',
  '#F0F0F0',
  '#D3ADF7',
  '#ADC6FF',
  '#B7EB8F',
  '#FFFB8F',
  '#FFD591',
  '#FFA39E',
];

export const BORDER_WIDHTS = [
  '1px',
  '2px',
  '3px',
  '4px',
  '5px',
  '6px',
  '7px',
  '8px',
  '9px',
  '10px',
];

export const BORDER_STYLES = [
  'none',
  'hidden',
  'dotted',
  'solid',
  'dashed',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
];

export const getComponentProps = ({ i, pageNumber, pages }) => {
  const layout = pages[pageNumber]?.layouts;
  return layout ? find(propEq(i, 'i'))(layout)?.initialLegendState : {};
};

export const CHART_DEFAULT_STATE = {
  isReport: true,
  showChartTitle: true,
  showChartDescription: true,
  showDataLabelOnChart: false,
};

export const CHART_VIEW_PROPERTIES_HIDE_DATA_LABEL_CHECKBOX = {
  hideDataLabelCheckbox: true,
};

export const TABLE_DENISITY_OPTIONS = {
  small: 'small',
  middle: 'middle',
  large: 'large',
};

export const TABLE_COLUMN_SIZE_OPTIONS = {
  fitToData: 'auto',
  distributeEvenly: 'fixed',
};

export const REPORT_TABLE_DEFAULT_STATE = {
  showTableName: true,
  showDataSource: true,
  showRowNumber: true,
  tableDensity: TABLE_DENISITY_OPTIONS.middle,
  columnSize: TABLE_COLUMN_SIZE_OPTIONS.fitToData,
  usePropertyMarket: true,
  rowsPerPage: 5,
  hideColumns: [],
};

export const updateComponentProps = ({ pages, i, pageNumber, props }) => {
  const updatedPages = pages?.map((page, idx) => {
    if (idx === pageNumber) {
      const layouts = page.layouts.map((layout) => {
        if (layout.i === i) {
          return { ...layout, initialLegendState: props };
        }
        return layout;
      });
      return { ...page, layouts };
    }
    return { ...page };
  });

  return updatedPages;
};

export const getDefaultCoordinateBbox = (country) => {
  const coordinates = COUNTRY_CAPITAL_COORDINATE[country];
  const coordinatesGeoJSON = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [coordinates[0], coordinates[1]],
    },
  };
  const bufferedPoint = turfBuffer(coordinatesGeoJSON, 1, {
    units: 'kilometers',
  });
  return bufferedPoint;
};

export const orderTemplateByRecentList = (
  templateObjects,
  recentTemplateIds,
) => {
  const templateIds = templateObjects?.map((template) => template?.id);

  // This checks if the template ids are valid (e.g. user remove template)
  const removedTemplateIds = difference(
    recentTemplateIds || [],
    templateIds || [],
  );

  // Fill more templates if recent templates are inadequate
  const recentTemplatesList = [
    ...(recentTemplateIds?.filter(
      (recentTemplate) => recentTemplate !== removedTemplateIds,
    ) || []),
    ...(templateObjects?.map((template) => template.id) || []),
  ];

  // Sort them based on pre-calculated list
  return sortBy(
    (obj) => (recentTemplatesList || []).indexOf(obj?.id),
    templateObjects || [],
  );
};
