import { array, object, string } from 'yup';

import * as disposableEmailsFile from './resources/disposable-email-provider-domains.json';


const MAX_CHARACTERS = 80;
const MIN_CHARACTERS = 1;
const TEXTAREA_MAX_CHARACTERS = 10000;

const disposableEmails = disposableEmailsFile.default;

const privateEmailRegex = new RegExp(
  '@(gmail|gmx|yahoo|hotmail|aol|msn|live|web|outlook|wanadoo|orange){1}.[a-zA-Z]+$',
);

const replaceEmailLocalPartRegex = new RegExp('^[\\w\\d.-]*@');

const isPrivateEmailAddress = (value) => privateEmailRegex.test(value);

const isDisposableEmailAddress = (value) =>
  disposableEmails.includes(
    (value || '').replace(replaceEmailLocalPartRegex, ''),
  );

const emailSchema = string()
  .trim()
  .max(MAX_CHARACTERS, 'Too many characters.')
  .required('Email is required.')
  .email('Email is not valid.')
  .test(
    'Email is not valid.',
    'Email is not valid.',
    (value) => !isDisposableEmailAddress(value),
  )
  .when('privateCustomer', {
    is: undefined,
    then: string().test(
      'Please provide a company email.',
      'Please provide a company email.',
      (value) => !isPrivateEmailAddress(value),
    ),
  });

const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const hubSpotContactSalesFormSchema = object().shape({
  firstName: string()
    .trim()
    .max(MAX_CHARACTERS, 'Too many characters.')
    .required('First name is required.'),
  lastName: string()
    .trim()
    .max(MAX_CHARACTERS, 'Too many characters.')
    .required('Last name is required.'),
  company: string()
    .max(MAX_CHARACTERS, 'Too many characters.')
    .trim()
    .required('Company name is required.'),
  phone: string()
    .trim()
    .matches(phoneRegExp, 'Phone number is not valid.')
    .nullable(),
});

export const pointOfInterestFormSchema = object().shape({
  title: string()
    .trim()
    .max(MAX_CHARACTERS, 'Too many characters.')
    .min(MIN_CHARACTERS, 'Title too short')
    .required('Title is required.'),
  description: string()
    .trim()
    .max(TEXTAREA_MAX_CHARACTERS, 'Too many characters.'),
  category: array(),
  visibility: string().required('Visibility is required.'),
  icon: string().required('Visibility is required.'),
  color: string().required('Visibility is required.'),
});