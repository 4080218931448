import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

const FadeAnimation = ({ children, id, show, timeout }) => (
  <CSSTransition
    key={id}
    in={show}
    timeout={timeout}
    classNames="transopacity"
    unmountOnExit
  >
    {children}
  </CSSTransition>
);

FadeAnimation.defaultProps = {
  timeout: 300,
  show: false,
};

FadeAnimation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  id: PropTypes.string.isRequired,
  show: PropTypes.bool,
  timeout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.objectOf(PropTypes.any),
  ]),
};

export default FadeAnimation;
