import * as Sentry from '@sentry/browser';
import { Button, Result } from 'antd';
import i18n from 'i18next';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const t = (key) => i18n.t(`components.${key}`);

class ErrorBoundary extends Component {
  state = { hasError: false, hasRetried: false };

  static getDerivedStateFromError = () => ({ hasError: true });

  componentDidCatch = (error, errorInfo) => {
    if (this.state.hasRetried) {
      window.location.reload();
      return;
    }
    this.setState({ hasError: true });
    Sentry.withScope((scope) => {
      scope.setTag('component', '<ErrorBoundary />');
      scope.setLevel('error');

      scope.setExtra('window inner width', window?.innerWidth);
      scope.setExtra('window inner height', window?.innerHeight);
      scope.setExtra('is online', navigator?.onLine);
      scope.setExtra('error name', error?.name);
      scope.setExtra('error message', error?.message);

      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  };

  resetState = () => {
    this.setState({ hasError: false, hasRetried: true });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex-center flex-column mh-100vh">
          <Result
            title={t('errorBoundaryTitle')}
            subTitle={t('errorBoundarySubTitle')}
            extra={
              <Button type="default" onClick={this.resetState}>
                {t('errorBoundaryCtaBtn')}
              </Button>
            }
          />
        </div>
      );
    }

    return this.props.children || null;
  }
}

function mapStateToProps(state) {
  return {
    user: state?.user?.info,
    company: state?.company,
    activeIndicator: state?.forecastStore?.activeIndicator,
    lastActiveIndicator: state?.forecastStore?.lastActiveIndicator,
  };
}

export default connect(mapStateToProps, null)(ErrorBoundary);
