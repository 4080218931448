import axios from 'axios';

const HUBSPOT_CONTACT_SALES_FORM_URL =
  'https://api.hsforms.com/submissions/v3/integration/submit/5509051/4d1b46d5-0e73-411c-a1e1-f76771cb31ea';
const HUBSPOT_WELCOME_EMAIL_FORM_URL =
  'https://api.hsforms.com/submissions/v3/integration/submit/5509051/83bc0356-2274-41c6-a3ce-03ed0f0fb4fc';

export function formatToHubSpot(formData = {}) {
  return Object.keys(formData).map((formField) => ({
    name: formField.toLowerCase(),
    value: formData[formField],
  }));
}

export const contactSalesForm = (formData) => {
  if (process.env.REACT_APP_ENV !== 'production') {
    return Promise.resolve();
  }
  return axios.post(HUBSPOT_CONTACT_SALES_FORM_URL, formData);
};

export const sendWelcomeEmail = (formData) => {
  if (process.env.REACT_APP_ENV !== 'production') {
    return Promise.resolve();
  }
  return axios.post(HUBSPOT_WELCOME_EMAIL_FORM_URL, formData);
};
