import {
  ON_SCROLL,
  SET_IS_DISTANCE_MEASUREMENT_ENABLED,
  SET_IS_KNOWLEDGE_BASE_SIDEBAR_OPEN,
  UI_ERROR,
  UI_LOADING,
} from './types';

export const uiLoading = () => (dispatch) => {
  dispatch({ type: UI_LOADING, payload: true });
};

export const uiError = () => (dispatch) => {
  dispatch({ type: UI_ERROR, payload: { isError: false } });
};

export const uiOnscroll = (value) => (dispatch) => {
  dispatch({ type: ON_SCROLL, payload: value });
};

export const setIsDistanceMeasurementEnabled = (isEnabled) => (dispatch) => {
  dispatch({ type: SET_IS_DISTANCE_MEASUREMENT_ENABLED, payload: isEnabled });
};

export const setIsKnowledgeBaseSidebarOpen = (isOpen) => (dispatch) => {
  dispatch({ type: SET_IS_KNOWLEDGE_BASE_SIDEBAR_OPEN, payload: isOpen });
};
