import turfArea from '@turf/area';
import bBoxPolygon from '@turf/bbox-polygon';
import turfCenter from '@turf/center';
import dayjs from 'dayjs';
import { cellToBoundary } from 'h3-js';
import { round } from 'mathjs';
import { intersection, isEmpty, isNil, mapObjIndexed, or } from 'ramda';
import { useEffect, useRef, useState } from 'react';

import { countries } from '../../helpers/country';

export const SCORE_COLOR = '#face67';

export const useStateSave = (defaultState) => {
  const [internalState, setInternalState] = useState(defaultState);
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const setState = (newState) => {
    isMounted.current && setInternalState(newState);
  };

  return [internalState, setState];
};

export const formatDate = (date) =>
  date === 'null' ? undefined : dayjs(date).format('DD.MM.YYYY');

export const setLayerVisibility = (map, layerName, isVisible) => {
  if (map && map.getLayer(layerName)) {
    map.setLayoutProperty(
      layerName,
      'visibility',
      isVisible ? 'visible' : 'none',
    );
  }
};

export const isMapboxNil = (value) =>
  or(or(isNil(value), value === 'null'), isEmpty(value));

export const getValueOrDefault = ({ value, unit = '', defaultValue = '' }) => {
  if (isMapboxNil(value)) {
    return defaultValue;
  }
  return `${value} ${unit}`.trim();
};

export const getBoundingBoxCenter = (boundingBox) => {
  if (!boundingBox) {
    return null;
  }

  const polygon = bBoxPolygon([
    boundingBox[1][0],
    boundingBox[0][1],
    boundingBox[0][0],
    boundingBox[1][1],
  ]);

  const center = turfCenter(polygon);

  return {
    ...center,
    geometry: {
      ...center.geometry,
      coordinates: [
        round(center.geometry.coordinates[0], 6),
        round(center.geometry.coordinates[1], 6),
      ],
    },
  };
};

export const simplifyBoundingBoxCoordinates = (
  boundingBox,
  fractionDigits = 8,
) => [
  [
    round(boundingBox[0][0], fractionDigits),
    round(boundingBox[0][1], fractionDigits),
  ],
  [
    round(boundingBox[1][0], fractionDigits),
    round(boundingBox[1][1], fractionDigits),
  ],
];

const isJSON = (str) => {
  try {
    const jsonObject = JSON.parse(str);
    return Array.isArray(jsonObject) || typeof jsonObject === 'object';
  } catch (e) {
    return false;
  }
};

export const parseMapboxFeature = (value) => {
  if (typeof value === 'string' && value === 'null') {
    return null;
  }
  if (typeof value === 'string' && isJSON(value)) {
    return JSON.parse(value);
  }
  return value;
};

export const getFeatureFromMapboxLayer = ({ e, map, layer }) => {
  const mapboxFeature = map
    ?.queryRenderedFeatures(e?.point)
    .find((feature) => feature?.layer?.id === layer);

  if (!mapboxFeature) {
    return null;
  }

  return {
    geometry: mapboxFeature.geometry,
    properties: mapObjIndexed(parseMapboxFeature, mapboxFeature?.properties),
  };
};

export const getDefaultCurrencyCodeByCountry = (country) => {
  switch (country) {
    case countries.FINLAND:
      return 'EUR';
    case countries.DENMARK:
      return 'DKK';
    case countries.SWEDEN:
      return 'SEK';
    case countries.MEXICO:
      return 'MXP';
    default:
      return null;
  }
};

export const intersects = (arrayA, arrayB) =>
  !isEmpty(intersection(arrayA || [], arrayB || []));

export const convertH3ToPolygon = (hexagonCellId) => ({
  coordinates: [cellToBoundary(hexagonCellId, true)],
  type: 'Polygon',
});

export const getMapBoundsArea = (mapBounds) => {
  if (!mapBounds) return null;
  const bBox = mapBounds?.flat();

  const polygon = bBoxPolygon(bBox);
  return turfArea(polygon);
};

export const toFeatureCollection = (features) => ({
  type: 'FeatureCollection',
  features,
});
