import { Button, Divider, Space } from 'antd';
import { assoc, reduce } from 'ramda';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { object, string } from 'yup';

import { Link } from 'react-router-dom';
import {
  loginGoogleUser as loginGoogleUserAction,
  loginMicrosoftUser as loginMicrosoftUserAction,
  loginUser as loginUserAction,
  uiError,
} from '../../actions';
import GoogleIcon from '../../images/icons/icon_google.svg';
import MicrosoftIcon from '../../images/icons/icon_microsoft.svg';

const loginSchema = object().shape({
  email: string()
    .required('Email is required')
    .email('Email is not valid')
    .label('emailError'),
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required')
    .label('passwordError'),
});

const LoginForm = ({
  isLoading,
  isError,
  errorMessage,
  loginGoogleUser,
  loginMicrosoftUser,
  loginUser,
  clearUiError,
}) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const onChangeEmail = ({ target: { value } }) => {
    setEmail(value);
    clearUiError();
  };

  const onChangePassword = ({ target: { value } }) => {
    setPassword(value);
    clearUiError();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      loginSchema.validateSync(
        { email, password },
        {
          strict: true,
          abortEarly: false,
          stripUnknown: true,
        },
      );

      loginUser({ email, password });
    } catch (error) {
      const errors = reduce(
        (acc, err) => assoc(err.params.label, err.message, acc),
        {},
        error?.inner || {},
      );

      setEmailError(errors['emailError']);
      setPasswordError(errors['passwordError']);
    }
  };

  const loginWithMicrosoft = (e) => {
    e.preventDefault();
    loginMicrosoftUser();
  };

  const loginWithGoogle = (e) => {
    e.preventDefault();
    loginGoogleUser();
  };

  return (
    <div className="loginform__wrapper dropshadow-5 br-5 py-2 fade">
      <form className="loginform">
        <h2 className="form-title text-center">Sign In</h2>

        <Space direction="vertical" size="small" className="tw-w-full">
          <Button
            size="large"
            className="tw-w-full"
            onClick={loginWithMicrosoft}
            icon={
              <img
                src={MicrosoftIcon}
                style={{ height: 24, width: 24 }}
                alt="microsoft-icon"
              />
            }
          >
            Sign in with Microsoft
          </Button>
          <Button
            size="large"
            className="tw-w-full"
            onClick={loginWithGoogle}
            icon={
              <img
                src={GoogleIcon}
                style={{ height: 22, width: 22 }}
                alt="google-icon"
              />
            }
          >
            <span className="tw-mr-3">Sign in with Google</span>
          </Button>
        </Space>

        <Divider>Or sign in with email</Divider>

        <input
          className="loginform__input br-5"
          name="email"
          value={email}
          onChange={onChangeEmail}
          type="email"
          autoComplete="username"
          placeholder="* Email"
          data-test-id="input-login"
        />
        {emailError && (
          <span className="fade text-error text-s">{emailError}</span>
        )}
        <br />
        <br />
        <input
          className="loginform__input br-5"
          name="password"
          value={password}
          type="password"
          autoComplete="current-password"
          onChange={onChangePassword}
          placeholder="* Password"
          data-test-id="input-password"
        />
        {passwordError && (
          <span className="fade text-error text-s">{passwordError}</span>
        )}
        <br />
        <br />
        {isLoading ? (
          <div className="relative">
            <span className="loading" />
          </div>
        ) : (
          <button
            className="btn__default br-5"
            onClick={handleSubmit}
            data-test-id="button-signin"
          >
            SIGN IN
          </button>
        )}
        <Link
          className="text-xs block text-grey-1 mt-2 text-right"
          to="/password-recovery"
        >
          Forgot password?
        </Link>
      </form>
      {isError && (
        <div className="text-center text-error text-s my-1 fade">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { isLoading, error } = state.ui;

  return {
    isLoading,
    isError: error.isError,
    errorMessage: error.message,
  };
}

export default connect(mapStateToProps, {
  loginMicrosoftUser: loginMicrosoftUserAction,
  loginGoogleUser: loginGoogleUserAction,
  loginUser: loginUserAction,
  clearUiError: uiError,
})(LoginForm);
