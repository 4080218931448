import { UPDATE_LOCATION_OF_INTEREST } from './types';

export const setIsLocationOfInterestSidebarOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: UPDATE_LOCATION_OF_INTEREST,
    payload: { isSidebarOpen: isOpen },
  });
};

export const setIsLocationOfInterestFilterViewOpen = (isOpen) => (dispatch) => {
  dispatch({
    type: UPDATE_LOCATION_OF_INTEREST,
    payload: { isFilterViewOpen: isOpen },
  });
};

export const setIsLocationOfInterestPointsVisible =
  (isVisible) => (dispatch) => {
    dispatch({
      type: UPDATE_LOCATION_OF_INTEREST,
      payload: { isPointsVisible: isVisible },
    });
  };

export const setLocationOfInterestFilters = (filters) => (dispatch) => {
  dispatch({
    type: UPDATE_LOCATION_OF_INTEREST,
    payload: { filters },
  });
};

export const setLocationOfInterestSearchString =
  (searchString) => (dispatch) => {
    dispatch({
      type: UPDATE_LOCATION_OF_INTEREST,
      payload: { searchString },
    });
  };

export const setLocationOfInterestVisibilityFilter =
  (visibilityFilter) => (dispatch) => {
    dispatch({
      type: UPDATE_LOCATION_OF_INTEREST,
      payload: { visibilityFilter, filters: {} },
    });
  };
