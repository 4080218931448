import monochromeIcon from './images/monochrome.png';
import satelliteIcon from './images/satellite.png';
import streetsLightMode from './images/streets-light-mode.png';

export const MAP_STYLES = [
  {
    id: 'monochrome',
    backwardsCompatibleIds: ['default'],
    description: 'monochromeDescription',
    styleUrl: 'mapbox://styles/chaosarchitects/cjsuexuy260vy1fru98h291v7',
    image: monochromeIcon,
  },
  {
    id: 'satellite',
    backwardsCompatibleIds: [],
    description: 'satelliteDescription',
    styleUrl: 'mapbox://styles/chaosarchitects/cl6xlk15v000014mxs58t7kdc',
    image: satelliteIcon,
  },
  {
    id: 'streetsLightMode',
    backwardsCompatibleIds: ['highlightedRoadsAndTrainTracks'],
    description: 'streetsLightModeDescription',
    styleUrl: 'mapbox://styles/chaosarchitects/clv22amop00aj01pk5lp19t31',
    image: streetsLightMode,
  },
];

/*
  This function allows us to map old style ids to new once. For example,
  "streetsLightMode" used to be called "streetsLightModeDescription".
  To avoid those maps to fall back to the default map style, we are using
  the "backwardsCompatibleIds".
 */
export const MAP_STYLES_MAP = MAP_STYLES.reduce((stylesMap, style) => {
  const backwardsCompatibleStyles = style.backwardsCompatibleIds.reduce(
    (styleResults, backwardsCompatibleId) => ({
      ...styleResults,
      [backwardsCompatibleId]: style,
    }),
    {},
  );

  return {
    ...stylesMap,
    ...backwardsCompatibleStyles,
    [style.id]: style,
  };
}, {});
