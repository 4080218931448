import { SHOW_FLASH_MESSAGE, HIDE_FLASH_MESSAGE } from '../actions/types';

export const flashMessagesVariant = {
  error: 'error',
  warning: 'warning',
  info: 'info',
  success: 'success',
};

export default function (state = [], action) {
  switch (action.type) {
    case SHOW_FLASH_MESSAGE:
      return state.concat(action.payload);

    case HIDE_FLASH_MESSAGE:
      return state.filter((message) => message.id !== action.payload);

    default:
      return state;
  }
}
