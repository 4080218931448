import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { v4 as uuid } from 'uuid';

import i18n from '../i18n';
import { firebasePostApi } from '../services/firebaseApi';
import { updateUser } from '../services/firebaseHelpers';
import store from '../store/store';
import { VERIFY_SSO_ACCESS_TOKEN_URL } from './apiUser';
import { LOGOUT_USER, UI_ERROR, UI_LOADING } from './types';

export const handleLoginSuccess = () => {
  const usid = uuid();
  localStorage.setItem('usid', usid);
  updateUser({ usid });
  store.dispatch({ type: UI_LOADING, payload: false });
};

export const handleLoginError = (message = '') => {
  store.dispatch({ type: UI_LOADING, payload: false });
  store.dispatch({ type: UI_ERROR, payload: { isError: true, message } });
};

const t = (key) => i18n.t(`dashboardView.${key}`, key);

const linkSsoCredentials = async (credentials, provider) => {
  const verificationResult = await firebasePostApi(
    VERIFY_SSO_ACCESS_TOKEN_URL,
    {
      provider,
      access_token: credentials.accessToken,
    },
  );

  if (verificationResult?.customToken) {
    firebase
      .auth()
      .signInWithCustomToken(verificationResult.customToken)
      .then(async (result) => {
        if (result.user) {
          // Link Microsoft credentials with user's firebase account
          await firebase.auth().currentUser.linkWithCredential(credentials);
          handleLoginSuccess();
        }
      })
      .catch(handleLoginError);
  } else {
    handleLoginError();
  }
};

const microsoftAuthProvider = new firebase.auth.OAuthProvider('microsoft.com');

microsoftAuthProvider.setCustomParameters({
  tenant: 'consumers',
  prompt: 'select_account',
});

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: 'select_account' });

const handleSSOSignInError = (error) => {
  if (error.code === 'auth/account-exists-with-different-credential') {
    // Link pending Microsoft credentials to to a firebase user to enable login using microsoft account
    const { credential } = error;
    linkSsoCredentials(credential, 'microsoft');
  } else if (error.code === 'auth/admin-restricted-operation') {
    handleLoginError(t('authAccessDeniedMessage'));
  } else {
    handleLoginError();
  }
};

export const loginMicrosoftUser = () => () => {
  firebase
    .auth()
    .signInWithPopup(microsoftAuthProvider)
    .then((result) => {
      if (result.user) {
        handleLoginSuccess();
      }
    })
    .catch(handleSSOSignInError);
};

export const loginGoogleUser = () => () => {
  firebase
    .auth()
    .signInWithPopup(googleAuthProvider)
    .then((result) => {
      if (result.user) {
        handleLoginSuccess();
      }
    })
    .catch(handleSSOSignInError);
};

export const loginUser = (account) => async (dispatch) => {
  const email = account.email.trim();
  const password = account.password.trim();
  try {
    dispatch({ type: UI_ERROR, payload: { isError: false } });
    dispatch({ type: UI_LOADING, payload: true });

    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        if (result.user) {
          handleLoginSuccess();
        }
      });
  } catch (err) {
    handleLoginError(t('authInvalidEmailPasswordMessage'));
  }
};

export const logoutUser = () => async (dispatch) => {
  await firebase.auth().signOut();
  if (window.tokenRenewTimer) clearTimeout(window.tokenRenewTimer);
  localStorage.removeItem('token');
  localStorage.removeItem('access_token');
  dispatch({ type: LOGOUT_USER });
  dispatch({ type: UI_LOADING, payload: false });
};
