import {
  CLEAR_FORECAST_DATA,
  SELECT_AREA,
  SET_ACTIVE_INDICATOR,
  SET_DATA_GROUPS,
  SET_IS_FORECAST_ACTIVE,
  SET_SEARCH_BY_AREA,
  SET_SHOW_SIDE_CONTENT,
} from './types';

export const selectArea = (type, data) => ({
  type: SELECT_AREA,
  payload: { type, data },
});

export const setDataGroups = (data) => ({
  type: SET_DATA_GROUPS,
  payload: data,
});

export const setIsForecastActive = (isForecastActive) => ({
  type: SET_IS_FORECAST_ACTIVE,
  payload: isForecastActive,
});

export const setActiveIndicator = (indicator) => ({
  type: SET_ACTIVE_INDICATOR,
  payload: indicator,
});

export const setShowSideContent = (value) => ({
  type: SET_SHOW_SIDE_CONTENT,
  payload: value,
});

export const setSearchByArea = (data) => ({
  type: SET_SEARCH_BY_AREA,
  payload: data,
});

export const clearForecastData = () => ({ type: CLEAR_FORECAST_DATA });
