import turfArea from '@turf/area';
import turfBbox from '@turf/bbox';
import turfBuffer from '@turf/buffer';
import turfIntersect from '@turf/intersect';
import { UNITS, getHexagonAreaAvg } from 'h3-js';
import { round } from 'mathjs';
import { isEmpty, isNil, or } from 'ramda';

import { getH3Cells } from '../../helpers/mapUtils';
import { toFeatureCollection } from '../common/helpers';

export const MAX_ALLOWED_AREA = 300 * (1000 * 1000); // square meters
const MAX_ALLOWED_HEXAGONS_L9 = 1000;

export const ERROR_CODE_DATA_NOT_AVAILABLE = 434;
export const ERROR_CODE_UNAUTHORIZED_AREA_ACCESS = 435;
export const ERROR_CODE_OUTSIDE_OF_TRIAL_AREA = 436;

export const AREA_SELECTION_MODES = {
  selectAreaBySearch: 'selectAreaBySearch',
  selectAreaByPolygon: 'selectAreaByPolygon',
  selectAreaByReachability: 'selectAreaByReachability',
};

export const REACHABILITY_MODES = {
  walking: 'walking',
  cycling: 'cycling',
  driving: 'driving',
  distance: 'distance',
};

export const AREA_SELECTION_KEYWORD_TYPES = {
  municipality: [
    'locality',
    'administrative_area_level_1',
    'administrative_area_level_2',
  ],
  postalCode: ['postal_code'],
};

export const getPolygonOverlapPercentage = (polygonA, polygonB) => {
  if (!polygonA || !polygonB) {
    return 0;
  }

  const intersection = turfIntersect(toFeatureCollection([polygonA, polygonB]));

  const intersectionArea = intersection ? turfArea(intersection) : 0;

  const newSelectionArea = turfArea(polygonA);
  const currentSelectedArea = turfArea(polygonB);

  const percentageOverlap = round(
    (intersectionArea /
      (newSelectionArea + currentSelectedArea - intersectionArea)) *
      100,
  );

  return percentageOverlap;
};

export const isValidPolygonFeature = (polygon) => {
  if (!polygon || !polygon?.geometry?.coordinates) return false;

  const polygonGeometry = polygon.geometry;

  return (
    polygonGeometry?.type === 'Polygon' &&
    polygonGeometry?.coordinates[0].length > 3
  );
};

export const polygonToBoundingBox = (polygon) => {
  const flattenedBoundingBox = turfBbox(polygon);

  return [
    [flattenedBoundingBox[0], flattenedBoundingBox[1]],
    [flattenedBoundingBox[2], flattenedBoundingBox[3]],
  ];
};

export const getSearchResolution = (boundingBoxAreaInSquareMeter) => {
  if (boundingBoxAreaInSquareMeter < 100_000) {
    return 11;
  }

  if (boundingBoxAreaInSquareMeter < 1_000_000) {
    return 10;
  }
  return 9;
};

export const polygonToH3Grid = (polygon, resolution) => {
  if (!polygon) return null;

  const polygonAreaM2 = turfArea(polygon);
  const avgHexagonAreaL9 = getHexagonAreaAvg(9, UNITS.m2);
  const totalHexagonsInArea = polygonAreaM2 / avgHexagonAreaL9;

  const targetResolution =
    totalHexagonsInArea <= MAX_ALLOWED_HEXAGONS_L9 ? 9 : 8;

  const hexagonIds = getH3Cells(
    polygon,
    resolution || targetResolution,
    getSearchResolution(polygonAreaM2),
    true,
  );

  return { hexagonIds, level: targetResolution };
};

export const scaleBoundingBox = (boundingBox, factor) => {
  if (!boundingBox || !factor) return null;

  const [southwest, northeast] = boundingBox;

  const [southLat, westLng] = southwest;
  const [northLat, eastLng] = northeast;

  const latDiff = northLat - southLat;
  const lngDiff = eastLng - westLng;

  const scaledSouthLat = southLat - latDiff * factor;
  const scaledWestLng = westLng - lngDiff * factor;
  const scaledNorthLat = northLat + latDiff * factor;
  const scaledEastLng = eastLng + lngDiff * factor;

  return [
    [scaledSouthLat, scaledWestLng],
    [scaledNorthLat, scaledEastLng],
  ];
};

export const isNilOrEmpty = (value) => or(isNil(value), isEmpty(value));

export const polygonToScaledBoundingBox = (polygon) => {
  if (isNilOrEmpty(polygon)) return null;

  const areaKM2 = turfArea(polygon) / (1000 * 1000);
  const bufferRadiusInKm = areaKM2 < 12 ? 1 : 2;

  const bufferedPolygon = turfBuffer(polygon, bufferRadiusInKm, {
    units: 'kilometers',
  });

  return polygonToBoundingBox(bufferedPolygon);
};

export const isSelectionAreaTypeSpecific = (areaType) =>
  AREA_SELECTION_KEYWORD_TYPES.municipality.includes(areaType) ||
  AREA_SELECTION_KEYWORD_TYPES.postalCode.includes(areaType);
