import { LOGIN_USER, LOGOUT_USER } from '../actions/types';

const DEFAULT_STATE = { authenticated: false };
export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, authenticated: true };
    case LOGOUT_USER:
      return { ...state, authenticated: false };
    default:
      return state;
  }
}