import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, selectorId = 'portal' }) =>
  ReactDOM.createPortal(children || null, document.getElementById(selectorId));

Portal.propTypes = {
  selectorId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Portal;
