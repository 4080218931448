import { compose, createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import reducers from '../reducers';

const initialState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let middleware = applyMiddleware(reduxThunk);

if (process.env.NODE_ENV !== 'production') {
  middleware = composeEnhancers(middleware);
}

const store = createStore(reducers, initialState, middleware);
export default store;
