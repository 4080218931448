import enFI from 'antd/locale/en_US';
import fiFI from 'antd/locale/fi_FI';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import { keys } from 'ramda';
import { initReactI18next } from 'react-i18next';

const AVAILABLE_LANGUAGE_CODES = {
  EN: 'en',
  FI: 'fi',
};

export const AVAILABLE_LANGUAGES = {
  [AVAILABLE_LANGUAGE_CODES.EN]: 'In English',
  [AVAILABLE_LANGUAGE_CODES.FI]: 'Suomeksi (beta)',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .init({
    backend: { loadPath: '/translations/{{lng}}.json' },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    supportedLngs: keys(AVAILABLE_LANGUAGES),
  });

export const getAntDesignLocaleFromLanguageCode = (language) => {
  switch (language) {
    case AVAILABLE_LANGUAGE_CODES.FI:
      return fiFI;
    default:
      return enFI;
  }
};

export default i18n;
