import { Button, Flex, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineClose } from 'react-icons/md';
import { useLocation } from 'react-router-dom';

import { gaEvents, sendGACommonEvent } from '../../../helpers/gaTracker';
import './_Onboarding.module.scss';

const { Text } = Typography;
const OnboardingContentComponent = ({ currentStep, steps, setIsOpen }) => {
  const { t } = useTranslation('', { keyPrefix: 'onboarding' });

  const location = useLocation();

  const content = steps[currentStep];

  const isLastStep = currentStep + 1 === steps.length;

  const renderTitle = (
    <Flex className="tw-w-full" justify="space-between">
      <Text strong className="tw-text-white">
        {content.title}
      </Text>
      <Button
        size="small"
        type="default"
        icon={<MdOutlineClose size={16} />}
        onClick={() => setIsOpen(false)}
        className="tw-text-white tw-bg-transparent tw-border-none"
      />
    </Flex>
  );

  useEffect(() => {
    const closeModalOnUnmount = () => {
      setIsOpen(false);
    };

    return () => closeModalOnUnmount();
  }, [location, setIsOpen]);

  const handleFinishTour = useCallback(() => {
    setIsOpen(false);
    sendGACommonEvent(gaEvents.FINISH_TOUR, {
      finishTitle: content.title,
    });
  }, [content.title, setIsOpen]);

  return (
    <Flex vertical gap={8}>
      {renderTitle}
      <Text className="tw-text-white">{content.content}</Text>
      {isLastStep && (
        <Button type="primary" onClick={handleFinishTour}>
          {t('finishTourBtn')}
        </Button>
      )}
    </Flex>
  );
};

OnboardingContentComponent.propTypes = {
  currentStep: PropTypes.number,
  setIsOpen: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.object),
};

OnboardingContentComponent.defaultProps = {
  currentStep: undefined,
  setIsOpen: undefined,
  steps: undefined,
};
export default OnboardingContentComponent;
