import { UPDATE_RETAIL } from '../actions/types';

const initialState = {
  isSettingsVisible: false,
  isAddNewPlaceActive: false,
  retailMasterMap: undefined,
  selectedInsights: [],
  csvUploadMode: undefined,
};

const retailReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_RETAIL:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default retailReducer;
