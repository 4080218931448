import { produce } from 'immer';

import {
  FORECAST_SET_IS_LOADING,
  SELECT_AREA,
  SET_ACTIVE_INDICATOR,
  SET_DATA_GROUPS,
  SET_IS_FORECAST_ACTIVE,
  SET_SEARCH_BY_AREA,
  SET_SHOW_SIDE_CONTENT,
} from '../actions/types';

const initialState = {
  isForecastActive: false,
  dataGroups: {},
  polygon: null,
  searchByArea: { center: null, polygon: null, bound: null },
  selectedIndicators: [],
  selectedProject: null,
  activeIndicator: null,
  postalcodes: [],
  postalCodesGeoJson: {},
  country: '',
  showSideContent: true,
  selectAreaState: {
    city: {},
    postalcodes: [],
  },
  lastActiveIndicator: null,
  isLoading: false,
  dashboardLinking: { from: '', isActive: false, popup: null, mapBound: null },
};

/* eslint default-case: "error" */
const forecastStoreReducer = produce((draft, action) => {
  switch (action.type) {
    case SELECT_AREA:
      if (action.payload.type === 'update') {
        draft.selectAreaState = action.payload.data;
      } else if (action.payload.type === 'select') {
        draft.selectAreaState.postalcodes =
          draft.selectAreaState.city.geoJson.data.map((p) => p.properties.Area);
      } else if (action.payload.type === 'clear') {
        draft.selectAreaState.postalcodes = [];
      }
      break;

    case SET_DATA_GROUPS:
      Object.keys(action.payload).forEach((key) => {
        draft[key] = action.payload[key];
      });
      break;

    case SET_IS_FORECAST_ACTIVE:
      draft.isForecastActive = action.payload;
      break;

    case SET_ACTIVE_INDICATOR:
      draft.lastActiveIndicator =
        draft.activeIndicator || action.payload || draft.lastActiveIndicator;
      draft.activeIndicator = action.payload;
      break;

    case SET_SHOW_SIDE_CONTENT:
      draft.showSideContent = action.payload;
      break;

    case FORECAST_SET_IS_LOADING:
      draft.isLoading = action.payload;
      break;

    case SET_SEARCH_BY_AREA:
      draft.searchByArea = action.payload;
      break;

    // No Default
  }
}, initialState);

export default forecastStoreReducer;
