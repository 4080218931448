import { isNil, reject } from 'ramda';

import { householdTypesEndpoints } from '../api/householdTypesIndicator';
import { countries } from '../helpers/country';
import { flashMessagesVariant } from '../reducers/flashMessage';
import { firebaseGetApi } from '../services/firebaseApi';
import { showFlashMessage } from './flashMessage';
import { setCommonSliderRange } from './timeSlider';
import {
  GET_ADVANCED_HOUSEHOLD_TYPES_DATA,
  GET_TARGET_CUSTOMER_SEARCH_HOUSEHOLD,
  UPDATE_HOUSEHOLD_TYPES_SELECTED_POSTCODE,
} from './types';


const TARGET_CUSTOMER_SEARCH_ENDPOINT = '/target-customer-search';
export const TARGET_CUSTOMER_SEARCH_HOUSEHOLD =
  'Target customer search household';
export const HOUSEHOLD_GROUPS = {
  'Households with children': 'with-children',
  'Households without children': 'without-children',
  'Pensioner households': 'pensioners',
  'Young households': 'young',
  'Households with small children (0-3)': 'with-small-children',
  'Households with children under school age (<7)':
    'with-under-school-children',
  'Households with children in school age (7-12)': 'with-school-children',
  'Households with teens (13-17)': 'with-teens',
};

export const countryToHouseholdIncomeMapping = (country) => {
  switch (country) {
    case countries.FINLAND:
      return {
        ...HOUSEHOLD_GROUPS,
        'Low income': 'low-income',
        'Middle income': 'medium-income',
        'High income': 'high-income',
      };
    case countries.DENMARK:
      return {
        ...HOUSEHOLD_GROUPS,
        'Income households below 200k DKK': 'below-200k-income',
        'Income households between 200k and 399k DKK': '200k-399k-income',
        'Income households between 400k and 599k DKK': '400k-599k-income',
        'Income households between 600k and 799k DKK': '600k-799k-income',
        'Income households more 800k DKK': 'more-800k-income',
      };
    default:
      return {};
  }
};

export const getAdvancedHouseholdTypesData =
  (endpoint, params, dataKey) => async (dispatch) => {
    try {
      dispatch({
        type: GET_ADVANCED_HOUSEHOLD_TYPES_DATA,
        payload: {
          dataKey,
          data: null,
          meta: { error: false, async: true },
        },
      });

      const response = await firebaseGetApi(endpoint, reject(isNil, params));

      dispatch({
        type: GET_ADVANCED_HOUSEHOLD_TYPES_DATA,
        payload: {
          dataKey,
          data: response,
          meta: { error: false, async: false },
        },
      });
      dispatch(
        setCommonSliderRange({
          minYear: response.minYear,
          maxYear: response.maxYear,
        }),
      );
    } catch (error) {
      dispatch({
        type: GET_ADVANCED_HOUSEHOLD_TYPES_DATA,
        payload: {
          dataKey,
          data: null,
          meta: { error: true, async: false },
        },
      });
    }
  };

export const updateHouseholdTypesSelectedPostcode =
  (params, isResetMode = false) =>
  async (dispatch) => {
    if (isResetMode) {
      dispatch({
        type: UPDATE_HOUSEHOLD_TYPES_SELECTED_POSTCODE,
        payload: {
          data: null,
        },
      });
      return;
    }
    try {
      const response = await firebaseGetApi(
        householdTypesEndpoints.supportingInformation,
        reject(isNil, params),
      );

      dispatch({
        type: UPDATE_HOUSEHOLD_TYPES_SELECTED_POSTCODE,
        payload: {
          data: response,
        },
      });
    } catch (err) {
      dispatch(
        showFlashMessage({
          id: new Date().getUTCMilliseconds(),
          variant: flashMessagesVariant.error,
          message: 'Oops! something went wrong.',
          autoHide: true,
          autoHideDuration: 4000,
        }),
      );
    }
  };

export const getTargetCustomerSearchHousehold =
  (queryParams, filterOptions) => async (dispatch) => {
    try {
      dispatch({
        type: GET_TARGET_CUSTOMER_SEARCH_HOUSEHOLD,
        payload: {
          key: TARGET_CUSTOMER_SEARCH_HOUSEHOLD,
          meta: { error: false, async: true },
        },
      });

      const response = await firebaseGetApi(
        TARGET_CUSTOMER_SEARCH_ENDPOINT,
        reject(isNil, queryParams),
      );

      dispatch(
        setCommonSliderRange({
          minYear: response.minYear,
          maxYear: response.maxYear,
        }),
      );

      dispatch({
        type: GET_TARGET_CUSTOMER_SEARCH_HOUSEHOLD,
        payload: {
          key: TARGET_CUSTOMER_SEARCH_HOUSEHOLD,
          data: { ...response, filters: filterOptions },
          meta: { error: false, async: false },
        },
      });
    } catch (err) {
      dispatch({
        type: GET_TARGET_CUSTOMER_SEARCH_HOUSEHOLD,
        payload: {
          key: TARGET_CUSTOMER_SEARCH_HOUSEHOLD,
          data: null,
          meta: { error: true, async: false },
        },
      });
    }
  };