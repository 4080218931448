import clx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {
  MdClose,
  MdDone,
  MdErrorOutline,
  MdInfoOutline,
  MdWarning,
} from 'react-icons/md';

import styles from './_index.module.scss';

export const messageIcons = {
  error: MdErrorOutline,
  warning: MdWarning,
  info: MdInfoOutline,
  success: MdDone,
};

export function ColorWrapper({ children, variant }) {
  return (
    <div className={clx(styles.wrapper, 'fade', styles[variant])}>
      {children}
    </div>
  );
}

ColorWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string.isRequired,
};

export const SingleMessage = ({ message, hideFlashMessage }) => {
  function renderMessageIcon() {
    const Icon = messageIcons[message.variant];
    return <Icon color="#fff" className="mr-2" />;
  }

  return (
    <ColorWrapper variant={message.variant}>
      {renderMessageIcon()}
      <p className="text-s text-light m-0">{message.message}</p>
      {hideFlashMessage && (
        <MdClose
          color="#fff"
          className="pointer ml-2"
          onClick={() => hideFlashMessage(message.id)}
        />
      )}
    </ColorWrapper>
  );
};

SingleMessage.defaultProps = {
  wrapperStyle: {},
  hideFlashMessage: undefined,
};

SingleMessage.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    message: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
  }).isRequired,
  hideFlashMessage: PropTypes.func,
  wrapperStyle: PropTypes.shape({}),
};

function Message({ message, hideFlashMessage }) {
  React.useEffect(() => {
    if (!message.autoHide) {
      return null;
    }
    const currentAutoHideDuration = message.autoHideDuration || 6000;

    const timerRef = setTimeout(() => {
      hideFlashMessage(message.id);
    }, currentAutoHideDuration);

    return () => timerRef && clearTimeout(timerRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SingleMessage message={message} hideFlashMessage={hideFlashMessage} />
  );
}

Message.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    message: PropTypes.string.isRequired,
    variant: PropTypes.string.isRequired,
    autoHide: PropTypes.bool.isRequired,
    autoHideDuration: PropTypes.number,
  }).isRequired,
  hideFlashMessage: PropTypes.func.isRequired,
};

export default Message;
