import { GET_COMPANY } from '../actions/types';

const DEFAULT_STATE = {};
export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case GET_COMPANY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
