import { produce } from 'immer';
import { flatten, isEmpty, keys, map, pipe, reject, uniq } from 'ramda';
import React from 'react';

import { TARGET_CUSTOMER_SEARCH_HOUSEHOLD } from '../../../actions';
import formatDecimal from '../../../helpers/formatDecimal';
import { indicators } from '../maps/constants';
import { unitMapHouseholds } from './filters';

export const householdTypesLegendTabs = {
  emergingAreas: 'emergingAreas',
  incomeTrend: 'incomeTrend',
  targetCustomerSearch: 'targetCustomerSearch',
};

export const householdTypesTabsText = {
  emergingAreasHeader:
    'Observe the pace of household change in different areas over the long period.',
  emergingAreasDetails:
    'The map colours shows different pace of the change. Darker green and red shades indicate that the number of households change has been, on average, accelerating or slowing down during last 7 years. The lighter shades indicate that the change has been happening at more constant pace.',
  incomeTrendHeader:
    'Identify areas with steady increasing or decreasing household income trends.',
  incomeTrendDetails:
    'Fastest growing areas indicate significant increasing trend in median household income during the last 5 years. Fastest shrinking areas indicate strong decline in median household income during last 5 years.',
  targetCustomerSearchHeader:
    'Explore size of your target demographic group within the selected area. Find out how your target group is concentrated in individual postal code and across selected area.',
  targetCustomerSearchDetails:
    'To discover how large is your target group in relation to all residents within individual postcode area, select "As share of postcode population" option.\nTo observe how large is your target group in relation to all residents of the entire selected area, select "As share of entire selected area" option. This approach allows examining whether your target group is concentrated in one locality or spread evenly across selected area.',
};

export const householdTypesIncomeTrend = {
  householdIncomeTrend: 'Household income trend',
  highIncomeHouseholds: 'High income households',
  lowIncomeHouseholds: 'Low income households',
  middleIncomeHouseholds: 'Middle income households',
  totalPurchasingPower: 'Total purchasing power',
};

export const gradiendLegendTexts = {
  emergingAreas: {
    left: 'Decelerating household change',
    right: 'Accelerating household change',
  },
  incomeTrend: {
    left: 'Declining household income',
    right: 'Growing household income',
  },
};

export function targetCustomerLegendText(group, area) {
  return group.length ? (
    <span>
      Share of {group.toLowerCase()} in the selected {area}
    </span>
  ) : null;
}

export function getTargetCustomerPopupText(textsArr, datasetOption, shareOf) {
  const result = [];
  textsArr.map((el) =>
    result.push(
      `${el} ${datasetOption.toLowerCase()} in the selected ${
        shareOf === 'selected-area' ? 'area' : 'postal code'
      }`,
    ),
  );
  return result;
}

export function formatValue(total, name, value) {
  if (value === 0) {
    return value;
  }
  if (
    name === 'household_growth_rate' ||
    name === 'average_size_of_households'
  ) {
    return formatDecimal(value, 2);
  }

  const arr = keys(unitMapHouseholds).slice(4);
  if (arr.includes(name)) {
    return formatDecimal((value / total) * 100, 2);
  }
  return value;
}

export function formatIncomeValue(name, value) {
  if (name === 'householdIncomeTrend') {
    return formatDecimal(value, 2);
  }

  return value;
}

export function checkMapData(data, activeLegendTab) {
  switch (activeLegendTab) {
    case householdTypesLegendTabs.emergingAreas:
      return !!data?.emergingAreas?.features?.length;

    case householdTypesLegendTabs.incomeTrend:
      return !!data?.incomeTrend?.features?.length;

    case householdTypesLegendTabs.targetCustomerSearch:
      return !!data?.targetCustomerSearch?.features?.length;

    default:
      throw new Error(`Unhandled type: ${activeLegendTab}`);
  }
}

export function makeMapData(data, householdTypesState, selectedYear) {
  switch (householdTypesState.selectedTab) {
    case householdTypesLegendTabs.emergingAreas:
      if (data?.emergingAreas?.features) {
        return produce(data.emergingAreas.features, (features) => {
          features.forEach((draft) => {
            draft.properties.id = draft.properties.area;
            draft.properties.growth_rate =
              draft.properties.data?.[selectedYear]?.household_growth_rate || 0;
          });
        });
      }
      return [];

    case householdTypesLegendTabs.incomeTrend:
      return produce(data.incomeTrend.features, (features) => {
        features.forEach((feature) => {
          feature.properties.id = feature.properties.area;
          feature.properties.income_trend =
            feature.properties.data?.[selectedYear]?.householdIncomeTrend || 0;
        });
      });

    case householdTypesLegendTabs.targetCustomerSearch:
      const selectedShareOf = getSelectedShareOf(householdTypesState.shareOf);
      if (isEmpty(householdTypesState?.datasetOptions)) {
        return [];
      }
      return produce(data.targetCustomerSearch.features, (features) => {
        features.forEach((feature) => {
          feature.properties.id = feature.properties.area;
          feature.properties[selectedShareOf] =
            feature.properties.data?.[selectedYear]?.[selectedShareOf] || 0;
        });
      });

    default:
      throw new Error(
        `Unhandled type: ${householdTypesLegendTabs.selectedTab}`,
      );
  }
}

export function getSelectedShareOf(currState) {
  return currState === 'selected-area' ? 'shareOfSelectedArea' : 'shareOfArea';
}

export function makeGradientValues(data, legendState, selectedYear) {
  switch (legendState.selectedTab) {
    case householdTypesLegendTabs.emergingAreas:
      return (
        data?.emergingAreas?.features
          ?.map(
            ({ properties: { data } }) =>
              data?.[selectedYear]?.household_growth_rate,
          )
          .filter(Boolean) || []
      );

    case householdTypesLegendTabs.incomeTrend:
      return (
        data?.incomeTrend?.features
          ?.map(
            ({ properties: { data } }) =>
              data?.[selectedYear]?.householdIncomeTrend,
          )
          .filter(Boolean) || []
      );
    case householdTypesLegendTabs.targetCustomerSearch:
      const selectedShareOf = getSelectedShareOf(legendState.shareOf);
      return (
        data?.targetCustomerSearch?.features
          ?.map(
            ({ properties: { data } }) =>
              data?.[selectedYear]?.[selectedShareOf],
          )
          .filter(Boolean) || []
      );
    default:
      return [];
  }
}

function getAdvancedHouseholdTypesYear(data, requiredFieldName) {
  const addRequiredData = (feature) =>
    keys(feature?.properties?.data).map((year) => ({
      year,
      requiredFieldValue:
        feature?.properties?.data?.[year]?.[requiredFieldName] || null,
    }));

  return pipe(
    map(addRequiredData),
    flatten,
    reject((item) => item.requiredFieldValue === null),
    map((item) => item.year),
    uniq(),
  )(data || []);
}

export function getAvailableYears(data, legendState) {
  if (legendState.selectedTab === householdTypesLegendTabs.emergingAreas) {
    return getAdvancedHouseholdTypesYear(
      data?.emergingAreas?.features,
      'household_growth_rate',
    );
  }

  if (legendState.selectedTab === householdTypesLegendTabs.incomeTrend) {
    return getAdvancedHouseholdTypesYear(
      data?.incomeTrend?.features,
      'householdIncomeTrend',
    );
  }

  if (
    legendState.selectedTab === householdTypesLegendTabs.targetCustomerSearch
  ) {
    const selectedShareOf = getSelectedShareOf(legendState.shareOf);
    return getAdvancedHouseholdTypesYear(
      data?.targetCustomerSearch?.features,
      selectedShareOf,
    );
  }

  return [];
}

export function mergeWithCustomerSearchData(forecastingData) {
  return produce(
    forecastingData[indicators['Advanced household types']],
    (draft) => {
      draft.targetCustomerSearch =
        forecastingData[TARGET_CUSTOMER_SEARCH_HOUSEHOLD];
    },
  );
}

export function showBarChartPlaceholder(selectedYearHouseholdStructure) {
  return selectedYearHouseholdStructure.length === 0;
}

export function getBarChartPlaceholderProps(selectedYearHouseholdStructure) {
  if (selectedYearHouseholdStructure.length === 0) {
    return {
      value: 'Data not available',
      textTransform: 'translate(106 105)',
    };
  }
}
