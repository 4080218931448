import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './_index.module.scss';

export const ButtonType = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  LINK: 'link',
  DELETE: 'delete',
};

function Button({
  type,
  onClick,
  children,
  isLoading,
  disabled,
  style,
  isSubmit,
}) {
  const renderButtonLoadingIndicator = () => (
    <div className={styles.buttonLoadingIndicator} style={style}>
      <span className="loading" />
    </div>
  );

  const renderPrimaryButton = () =>
    isLoading ? (
      renderButtonLoadingIndicator()
    ) : (
      <button
        onClick={onClick}
        className="bttn primary w-full md"
        disabled={disabled}
        style={style}
        type={isSubmit ? 'submit' : 'button'}
      >
        <span>{children}</span>
      </button>
    );

  const renderSecondaryButton = () =>
    isLoading ? (
      renderButtonLoadingIndicator()
    ) : (
      <button
        onClick={onClick}
        className="bttn outline w-full md"
        disabled={disabled}
        style={style}
        type={isSubmit ? 'submit' : 'button'}
      >
        <span>{children}</span>
      </button>
    );

  const renderDeleteButton = () =>
    isLoading ? (
      renderButtonLoadingIndicator()
    ) : (
      <button
        onClick={onClick}
        className={cx('bttn w-full md', {
          [styles.deleteButton]: true,
        })}
        disabled={disabled}
        style={style}
        type={isSubmit ? 'submit' : 'button'}
      >
        <span>{children}</span>
      </button>
    );

  const renderLinkButton = () => (
    <button
      onClick={onClick}
      className={styles.linkButton}
      disabled={isLoading || disabled}
      style={style}
      type={isSubmit ? 'submit' : 'button'}
    >
      <span>{children}</span>
    </button>
  );

  switch (type) {
    case ButtonType.LINK:
      return renderLinkButton();
    case ButtonType.SECONDARY:
      return renderSecondaryButton();
    case ButtonType.DELETE:
      return renderDeleteButton();
    default:
      return renderPrimaryButton();
  }
}

Button.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  isSubmit: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
};

Button.defaultProps = {
  onClick: () => true,
  isLoading: false,
  disabled: false,
  isSubmit: false,
  style: {},
};

export default Button;
