import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

import { firebaseGetApi, firebasePostApi } from '../services/firebaseApi';
import {
  GET_AVAILABLE_FEATURES,
  GET_GLOBAL_FEATURES,
  GET_UNAVAILABLE_FEATURES,
  GET_USER,
  GET_USER_INFO,
  SET_USER_GUIDE_COMPLETED,
  SET_USER_ONBOARDING_SEEN,
  SET_USER_RETAIL_SETTINGS,
  SET_USER_SETTINGS,
} from './types';

export const GLOBAL_FEATURES_API_URL = '/user/global-features';
export const VERIFY_SSO_ACCESS_TOKEN_URL = '/verify-sso-token';

export const getUser = async (dispatch) => {
  try {
    const currentUser = await firebase.auth().currentUser;
    if (currentUser) {
      const userData = await firebase
        .database()
        .ref(`users/${currentUser.uid}`)
        .once('value')
        .then((snapshot) => snapshot.val());
      dispatch({ type: GET_USER, payload: userData });
    }
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export const getAvailableFeatures = (country) => async (dispatch) => {
  try {
    const features = await firebaseGetApi(`/user/features?Country=${country}`);
    dispatch({ type: GET_AVAILABLE_FEATURES, payload: features });
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export const getUnavailableFeatures = (country) => async (dispatch) => {
  try {
    const features = await firebaseGetApi(
      `/user/features-unavailable?Country=${country}`,
    );
    dispatch({ type: GET_UNAVAILABLE_FEATURES, payload: features });
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export const getGlobalFeatures = async (dispatch) => {
  try {
    const features = await firebaseGetApi(GLOBAL_FEATURES_API_URL);
    dispatch({ type: GET_GLOBAL_FEATURES, payload: features });
  } catch (err) {
    // Wait for a short period before retrying
    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    await getGlobalFeatures(dispatch);
  }
};

export const getUserInfo = async (dispatch) => {
  try {
    const features = await firebaseGetApi('/user');
    dispatch({ type: GET_USER_INFO, payload: features });
    return features;
  } catch (err) {
    // Wait for a short period before retrying
    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    return getUserInfo(dispatch);
  }
};

export const updateUserConfig = (settings) => async (dispatch) => {
  try {
    const settingsResult = await firebasePostApi('/user/settings', settings);
    dispatch({ type: SET_USER_SETTINGS, payload: settingsResult });
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export const updateUserOnboardingSeen = (onboardings) => async (dispatch) => {
  try {
    const results = await firebasePostApi('/user/onboarding-seen', {
      onboardingSeen: onboardings,
    });
    dispatch({ type: SET_USER_ONBOARDING_SEEN, payload: results });
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export const updateUserGuideCompleted = (guides) => async (dispatch) => {
  try {
    const results = await firebasePostApi('/user/guide-completed', {
      guideCompleted: guides,
    });
    dispatch({ type: SET_USER_GUIDE_COMPLETED, payload: results });
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export const updateUserDashboardConfig =
  (dashboardSettings) => async (dispatch) => {
    try {
      await firebasePostApi('/user/dashboard-settings', dashboardSettings);
      await getUserInfo(dispatch);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

export const updateUserRetailConfig = (retailSettings) => async (dispatch) => {
  const retailSettingsResult = await firebasePostApi(
    '/user/retail-settings',
    retailSettings,
  );
  dispatch({ type: SET_USER_RETAIL_SETTINGS, payload: retailSettingsResult });
  await getUserInfo(dispatch);
};
