/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';
import { produce } from 'immer';
import { difference, isNotEmpty, keys, range } from 'ramda';

import {
  SET_COMMON_SLIDER_DATA,
  SET_COMMON_SLIDER_RANGE,
  SET_COMMON_SLIDER_YEAR,
  SET_SLIDER_INDICATOR_DATA,
  UPDATE_SLIDER_INDICATOR_DATA,
} from '../actions/types';

export const currentYear = dayjs().year();

const DEFAULT_YEAR = 2019;

const DEFAULT_STATE = {
  commonSliderYear: DEFAULT_YEAR,
  minYear: 2015,
  maxYear: currentYear,
  activeFormat: 'year',
  year: DEFAULT_YEAR,
  month: 0,
  day: 0,
  unavailableYears: [],
  forecastedYears: [],
};

/* eslint default-case: "error" */
export default produce((draft, { type, payload }) => {
  switch (type) {
    case SET_COMMON_SLIDER_RANGE:
      draft.maxYear = payload?.maxYear
        ? Math.max(draft.maxYear, payload.maxYear)
        : draft.maxYear;
      draft.minYear = payload?.minYear
        ? Math.min(draft.minYear, payload.minYear || draft.maxYear - 3)
        : draft.minYear;
      const rangeYears = range(draft.minYear, draft.maxYear + 1);
      const availableYears = keys(payload?.dataSourcesByYear).map((year) =>
        parseInt(year, 10),
      );
      draft.unavailableYears = isNotEmpty(availableYears)
        ? difference(rangeYears, availableYears)
        : [];
      draft.forecastedYears = payload?.forecastedYears || [];
      break;

    case SET_COMMON_SLIDER_YEAR:
      draft.year = payload;
      break;

    case SET_COMMON_SLIDER_DATA:
      draft.year = payload.year || draft.year;
      draft.activeFormat = payload.activeFormat || draft.activeFormat;
      draft.month = payload.month ?? draft.month;
      draft.day = payload.day ?? draft.day;
      break;

    case SET_SLIDER_INDICATOR_DATA:
      draft[payload.indicatorName] = {
        data: payload.data,
        activeFormat: payload.activeFormat,
        year: payload.year,
        month: payload.month,
        day: payload.day,
      };
      break;

    case UPDATE_SLIDER_INDICATOR_DATA:
      draft[payload.indicatorName].activeFormat = payload.activeFormat;
      draft[payload.indicatorName][payload.activeFormat] = payload.value;
      break;

    // No Default
  }
}, DEFAULT_STATE);
